.main-content-body {
  font-size: var(--wl-font-size-medium);
}

.main-content-element {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  padding: 20px 10px;
}

#featured_boats {
  background: initial;
}
#featured_boats > div {
  padding-bottom: 0;
}

.ad.w728:empty {
  display: none;
}

.hero.premium-featured-ad .ad#div-gpt-ad-PFBA {
  height: 0px;
}

.information-content {
  width: 100%;
  background-color: var(--wl-background-default-footer);
  color: var(--wl-text-color-ten);
  padding: 0 16px;
  box-sizing: border-box;
}

.information-content .collapse-content .header h3 {
  margin: 0;
  cursor: pointer;
}

.information-content .collapse-content > .header span a .icon, .information-content .collapse-content > .header h3 a .icon {
    background: url(../../../icons/DownArrow.svg) no-repeat;
}

.information-content .collapse-content .header h3 > a {
  font-size: var(--wl-font-size-medium);
}

.homepage-seo-content {
  max-width: var(--wl-max-content-width);
  line-height: var(--wl-line-height-medium);
  font-size: var(--wl-font-size-medium);
  margin: 0 auto;
  padding: 24px;

}

.homepage-seo-content a {
  color: var(--wl-text-color-ten);
  text-decoration: underline;
}
.homepage-seo-content a:hover,
.homepage-seo-content a:focus {
  color: var(--wl-text-color-eleven);
}

.home-block .homepage-seo-content a:hover, .home-block .homepage-seo-content a:focus {
  color: var(--wl-background-color-nineteen);
}

#Home.page-container .hero {
  position: relative;
  width: 100%;
  height: auto;
}

#Home.page-container .hero.premium-featured-ad,
#Home.page-container .hero.with-ad {
  overflow-y: hidden;
  /* we want the height of hero container without ad to be the same as the google hero ad. 
  And the height changed proportionally when the screen width changed to fix CLS issue */
  height: calc(100vw * 0.3417);
}

@media screen and (min-width: 375px) {
  .main-content-element {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

.fsbo-mini-ad {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 16px 15px;
  text-align: center;
}

.fsbo-mini-ad a {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  font-weight: bold;
  color: var(--wl-text-color-eleven);
  padding: 8px 16px;
  margin-left: 16px;
  background: var(--wl-content-background-color-default);
  border-radius: 4px;
  border-color: var(--wl-border-color-eight);
  border: 2px solid;
  cursor: pointer;
}

.latest-boats {
  background-color: var(--wl-content-background-color-default);
}

.latest-boats .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
  margin: 16px 0;
}

.latest-boats .view-all-boats {
  font-family: var(--wl-font-family-default);
  font-weight: bold;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  text-decoration-line: underline;
  color: var(--wl-text-color-one);
  background-image: url(../../../icons/ArrowForward.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center right;
  padding-right: 18px;
}

.private-featured {
  background-color: #F2F2F2;
  margin: 30px 0px;
}

.private-featured .main-content-element {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: calc(var(--wl-max-content-width) - 3%);
}

.private-featured .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
}

.main-content-body .ad {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  #Home.page-container .hero.premium-featured-ad,
  #Home.page-container .hero.with-ad {
    padding-bottom: 100px;
  }
}


@media screen and (max-width: 769px) {
  .main-content-body:nth-child(4) > div:not(:nth-child(2)) {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 769px) {

  .main-content-body:nth-child(4) > div:not(:nth-child(3)) {
    max-width: 100%;
    width: 100%;
  }

  .content.nav-slide.home-block .main-content-body .main-content-element.blog {
    max-width: var(--wl-max-content-width);
  }

  .qs-ad-content .ad {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .homepage-seo-content {
    margin-top: 24px;
  }

  .homepage-seo-content.block-editorial {
    max-width: var(--wl-max-content-width);
    margin-top: 0;
    padding-top: 0;
  }
}

@media screen and (min-width: 1440px) {
  #Home.page-container .hero.premium-featured-ad,
  #Home.page-container .hero.with-ad {
    height: 500px;
  }
}

.debug-message {
  display: none;
  height: 0;
}

.fb-yachts h1.fb-label, .fb-yachts h2.fb-label {
  padding: 0;
  margin: 0 0 24px 0;
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
}
.fb-yachts .fb-cards .featured-boat {
  position: relative;
  border-radius: 6px;
  color: white;
  overflow: hidden;
  height: 250px;
}
.fb-yachts .fb-cards .featured-boat > a {
  margin: 0;
  padding: 0;
  color: white;
  text-decoration: none;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient {
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.5) 75%);
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-logo {
  margin: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;
  width: 80px;
  height: 40px;
  border-radius: 4px;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-logo img {
  border-radius: 6px;
  max-height: 100%;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content {
  position: absolute;
  margin: 12px;
  bottom: 0;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p {
  margin: 0;
  padding: 0;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-featured {
  font-size: 10px;
  padding: 2px 8px;
  text-transform: uppercase;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  color: #0F4D77;
  border-radius: 6px;
  display: none;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-price {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-location {
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
}

.fb-yachts .fb-card-image {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .fb-cards {
    display: block;
  }
  .fb-cards .featured-boat {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) {
  .fb-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .fb-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
}


.spinner-preloader.hidden {
  display: none;
}

.spinner-preloader {
  width: 100%;
  position: absolute;
  height: 100%;
  background: var(--wl-preload-background-image);
}

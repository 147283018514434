ol.boat-list {
  margin: 12px 0;
  padding: 0;
  list-style: none;
}

ol.boat-list:after {
  display: table;
  content: '';
  clear: both;
}

ol.boat-list li.desktop-liner-ad,
ol.boat-list li.mobile-liner-ad {
  border: 0px none;
  text-align: center;
}

#SearchResults .stickyLeaderboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 315px;
  box-sizing: border-box;
  z-index: 1000;
}
#SearchResults .stickyLeaderboard #div-gpt-ad-leaderboard-alpha {
  margin: 0;
}
#SearchResults .main-footer {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .mobile-liner-ad.premium.bp1.ad-320x80 {
    min-height: 265px;
  }
}

@media screen and (max-width: 767px) {
  li.lib-card {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

.loading-container .boat-list.loading {
  display: block;
  width: 100%;
}

.loading-container .lib-card {
  height: 350px;
}

.loading-container .lib-card .inner {
  position: relative;
  overflow: hidden;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-filter: drop-shadow(0 2px 1px rgba(0, 0, 0, .2));
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, .2));
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  height: 100%;
}

.loading-container .lib-card .image-container {
  min-width: 100%;
  position: relative;
  overflow: hidden;
  width: 45%;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.loading-container .lib-card .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.loading-container .lib-card .image-container .image .preloader {
  z-index: 3;
  background: var(--wl-preload-background-image);
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}

@media screen and (min-width: 768px) {
  ol.boat-list li.desktop-liner-ad {
    width: 100%;
    margin: 0 auto 12px;
    text-align: center;
  }
  .loading-container .boat-list.loading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
    align-items: stretch;
    align-content: start;
    width: 100%;
  }
}

.video-tour-form-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--wl-background-color-ten);
}

#video-tour-form {
  box-shadow: 0 5px 15px var(--wl-box-shadow-two);
  position: relative;
  background-color: var(--wl-content-background-color-default);
  padding: 10px;
  width: 60%;
}

#video-tour-form > button.contact-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: auto;
  border: none;
  font-weight: 0;
  font-size: var(--wl-font-size-xx-large);
  z-index: 1;
  color: var(--wl-text-color-twelve);
}

.icon-x:before {
    content: "x";
}

#video-tour-form > button.contact-close > .aria-fixes {
  display: none;
}

#video-tour-form .main-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

#video-tour-form .main-form .title {
  color: var(--wl-text-color-link);
  font-weight: 700;
  margin: 5px 0;
}

#video-tour-form .main-form .subtitle {
  font-weight: 400;
  font-size: var(--wl-font-size-xx-small);
  color: var(--wl-text-color-link);
  margin-bottom: 10px;
}

#video-tour-form .submit-container {
  padding: 15px;
  margin-top: 10px;
}

#video-tour-form .main-form button.btn-action {
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding: 12px 0;
  line-height: var(--wl-line-height-medium);
  font-size: var(--wl-font-size-small);
  font-weight: 700;
  background-color: var(--wl-background-color-one);
  color: var(--wl-text-color-four);
}

#video-tour-form fieldset {
  border: none;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

#video-tour-form input[type="text"] {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-seven);
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 0.5em 10px;
  box-shadow: none;
}

#video-tour-form .main-form .message-error {
  color: var(--wl-text-color-nine);
  clear: both;
  padding: 15px 0;
  text-align: left;
  font-size: var(--wl-font-size-xx-small);
}

#video-tour-form .message-sent {
  color: var(--wl-text-color-link);
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
  display: flex;
  min-height: 200px;
  margin: 40px;
}

#video-tour-form .message-sent-error {
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
  display: flex;
  min-height: 200px;
  margin: 40px;
}

#video-tour-form .message-box {
  color: var(--wl-text-color-link);
  padding: 15px;
}

#video-tour-form .message-box .message-title {
  font-size: var(--wl-font-size-h3);
  font-weight: 700;
}

#video-tour-form .message-sent p,
#video-tour-form .message-sent-error p {
  margin-top: 0;
  font-size: var(--wl-font-size-small);
}

@media screen and (min-width:1025px) {
  #video-tour-form {
    width: 30%;
  }
}


.nearby-listings-message-container {
  border: 1px solid var(--wl-border-color-default);
  margin-bottom: 15px;
  display: flex;
}

.nearby-listings-message-arrow {
  background-image: url(../../icons/SimilarListingsArrowIcon.svg);
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}
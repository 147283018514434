.input-autocomplete-container .autocomplete-options {
  background-color: var(--wl-brand-color-three);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  margin-right: 16px;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button {
  display: block;
  background-color: var(--wl-brand-color-three);
  border: 0px solid var(--wl-brand-color-three);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  padding: 8px 12px;
  text-align: initial;
  transition-duration: 0.4s;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled],
.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled]:focus,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled]:hover {
  background-color: inherit;
  color: inherit;
  cursor: default;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button:focus,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button:hover,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button.selected {
  color: var(--wl-text-color-four);
  background-color: var(--wl-brand-color-one);
}

.input-autocomplete-container.input-makemodel .autocomplete-options {
  max-height: 284px;
  overflow-y: auto;
}

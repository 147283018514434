.tooltip-modal-actionable > div  {
  background: unset !important;
}

.tooltip-modal-actionable > div > div {
  background: unset !important;
  border: unset !important;
}

.monthly-price {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 0.934rem;
  color: #119BF5;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  z-index: 1000;
}

.monthly-price .info-icon {
  padding-left: .35rem;
  padding-top: 2px;
}

.monthly-price .info-icon > img{
  width: 14px;
  height: 14px;
}

.tooltip-wrapper .react-tooltip-lite {
  max-width: 232px !important;
  height: 174px;
  background: none;
  margin: -1px;
  padding: unset !important;
  margin-left: 10px;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  background: #35495D;
  border: 1px solid #EDEDED;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: auto;
  color: #EDEDED;
  font-family: 'Lato', sans-serif;
  padding: 16px 12px;
  gap: 8px;
  margin-left: 12px;
}

.tooltip-wrapper .tooltip-monthly .tooltip-monthly-head {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-head-monthlyPayment {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close {
  cursor: pointer;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img {
  width: 12px;
  opacity: .8;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img:hover {
  opacity: 1;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-body .tooltip-monthly-body-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #F5F5F5;
  margin: 0;
  padding-bottom: 8px;
}


/* Mobile */
@media screen and (max-width: 767px) {

  .tooltip-wrapper {
    width: fit-content !important;
  }

  .tooltip-wrapper .react-tooltip-lite {
    height: unset !important;
    width: auto !important;
    z-index: 1000 !important;
    max-width: 51% !important;
    margin-left: unset !important;
  }

  .tooltip-wrapper .monthly-price{
    justify-content: flex-start;
    margin-bottom: 5px;
    font-size: 12px !important;
    font-family: 'Roboto';
    font-weight: 700;
  }

  .tooltip-wrapper .tooltip-monthly {
    background: #FFFFFF !important;
    box-shadow: -10px 4px 30px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 5px 9px !important;
    
  }

  .tooltip-wrapper .react-tooltip-lite + .react-tooltip-lite-arrow {
    border-color: #FFFFFF;
  }

  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-head-monthlyPayment {
    color: #404040 !important;
    font-size: 10px !important;
  }
  
  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-body-paragraph {
    font-family: 'Roboto' !important;
    color: #404040 !important;
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .tooltip-wrapper.right:not(.enhanced) .tooltip-monthly {
    margin-top: -45px;
    max-width: 170px;
  }
  .tooltip-wrapper.right:not(.enhanced) .react-tooltip-lite-right-arrow {
    margin-top: -25px;
  }

  .tooltip-wrapper.enhanced {
    float: right;
  }

  .tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
    margin-left: 0px;
  }

  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-head {
    margin-bottom: -7px;
  }
  
}
.thumbs-wrapper {
  position: relative;
}

.carousel-thumbs {
  overflow: hidden;
}

.carousel-thumbs__wrapper {
  white-space: nowrap;
  position: relative;
  transition: transform 0.3s;
}

.carousel-thumbs__img-wrapper {
  width: 99%;
  min-height: 270px;
  display: inline-block;
  overflow: hidden;
  margin-top: 1%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.carousel-thumbs__img-wrapper.selected {
  position: relative;
}

.carousel-thumbs__img-wrapper.selected:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  /* box-shadow: inset 0 0 0 4px var(--wl-border-color-five); */
}

.carousel-thumbs__img {
  width: 90%;
  height: 100%;
  object-fit: contain;
}


.thumbs-wrapper .control-next.control-arrow:hover {
  background-color: lightgray;
  transition: 0.2s;
}


.thumbs-wrapper .control-prev.control-arrow:hover {
  background-color: lightgray;
  transition: 0.2s;
}


.thumbs-wrapper .control-next.control-arrow {
  /* border-right: 1px solid var(--wl-border-color-default); */
  margin-right: 0.36em;
  position: absolute;
  display: inline-block;
  width: 3em;
  height: 6em;
  background-image: url('../../icons/RightArrow.svg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: 30% 30%;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}

.thumbs-wrapper .control-next.control-arrow.hide {
  display: none;
}

.thumbs-wrapper .control-prev.control-arrow.hide {
  display: none;
}

.thumbs-wrapper .control-prev.control-arrow {
  /* border-left: 1px solid var(--wl-border-color-default); */
  margin-left: 0.36em;
  position: absolute;
  display: inline-block;
  width: 3em;
  height: 6em;
  background-image: url('../../icons/LeftArrow.svg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-size: 30% 30%;
  top: 40%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .carousel-thumbs__img-wrapper {
    height: 300px;
  }

  .carousel-thumbs__img-wrapper {
    width: 24%;
  }
}

.carousel-thumbs__img-wrapper>.listing-result.listing-result-card {
  max-width: 100% !important;
  margin-left: 0px !important
}

.carousel-thumbs__img-wrapper>.listing-result.listing-result-card .description {
  padding: 0 0 2% 2% !important;
}

.button-search {
  grid-column: span 2;
  background: var(--wl-background-color-one);
  border: 1px solid var(--wl-background-color-one);
  border-radius: 4px;
  color: var(--wl-text-color-four);
  font-weight: bold;
  font-size: var(--wl-font-size-medium);
  height: 43px;
  line-height: var(--wl-line-height-default);
  padding: 12px 12px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.bottom-row {
  align-items: flex-end;
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, fit-content(100%));
  justify-content: center;
}

.quick-search {
  background-color: var(--wl-brand-color-three);
  padding: 30px 16px;
  width: auto;
  box-sizing: border-box;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.08),
    0 2px 4px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.08);
}

.quick-search-container h1 {
  font-style: normal;
  font-weight: 900;
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
  color: var(--wl-text-color-default);
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.83);
  margin-top: -2px;
}

.quick-search .option-item-button:not(.title) {
  cursor: pointer;
}

.quick-search .option-item-button:not(.title)[disabled],
.quick-search .option-item-button:not(.title)[disabled]:focus,
.quick-search .option-item-button:not(.title)[disabled]:hover {
  background-color: inherit;
  color: inherit;
  cursor: default;
}

.quick-search .option-item-button:not(.title):focus,
.quick-search .option-item-button:not(.title):hover,
.quick-search .option-item-button:not(.title).selected {
  color: var(--wl-text-color-four);
  background-color: var(--wl-brand-color-one);
}

.quick-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.top-row .quick-search-category-selectors-label {
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-small);
}

.top-row .quick-search-category-selectors-container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(2, 50%);
  padding-bottom: 6px;
}

.input-length {
  grid-column: span 2;
}

/* Hide arrows on numeric inputs */
.quick-search input[type='number']::-webkit-inner-spin-button,
.quick-search input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quick-search input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (min-width: 768px) {
  .button-search,
  .input-length {
    grid-column: auto;
  }

  .bottom-row {
    justify-content: left;
    grid-template-columns: repeat(4, 140px) 112px;
  }

  .bottom-row__no-location {
    grid-template-columns: repeat(2, 1fr) repeat(2, 129px);
  }

  .quick-search {
    padding: 18px 45px;
    height: 180px;
  }

  .top-row .quick-search-category-selectors-label,
  .bottom-row label {
    font-size: var(--wl-font-size-small);
    font-weight: 700;
    color: var(--wl-text-color-six);
  }

  .top-row .quick-search-category-selectors-container {
    grid-template-columns: repeat(4, fit-content(100%));
  }
}

@media screen and (min-width: 970px) {
  .quick-search {
    width: 768px;
    background-color: var(--wl-brand-color-three);
    box-shadow:
      0px 4px 8px rgba(0, 0, 0, 0.08),
      0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: absolute;
    top: -39px;
  }

  .quick-search-container {
    position: relative;
    box-shadow: none;
    height: 139px;
  }

  .bottom-row {
    grid-template-columns: repeat(5, 129px);
  }

  .bottom-row__no-location {
    grid-template-columns: repeat(4, 120px) repeat(2, 100px) 60px;
  }
}

.qs-block .qs-ad-flex a {
  line-height: 0;
}

#quick-search-content {
  display: block;
}

.qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container {
  display: inline-block;
  border-bottom: 1px solid #F2F2F2;
  flex-grow: 1;
}

.qs-block .qs-filters .quick-search-container .quick-search .top-row {
  display: flex;
  justify-content: space-between;
}

.qs-filters .quick-search-container .quick-search .top-row label {
  padding: 0 10px;
  cursor: pointer;
  color: #999999;
  font-weight: 500;
}

.qs-filters .quick-search-container .quick-search .top-row .qs-checked {
  color: var(--wl-text-color-twenty);
  border-bottom: 2px solid var(--wl-background-color-nineteen);
}

.qs-filters .quick-search-container .quick-search .top-row .qs-category-advanced-search {
  color: var(--wl-text-color-twenty);
  border-bottom: 1px solid #F2F2F2;
  min-width: 120px;
  white-space: nowrap;
  cursor: pointer;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row {
  color: var(--wl-text-color-twenty);
  font-weight: 600;
}

.qs-filters .quick-search-container .quick-search .top-row input[type=radio] {
  opacity: 0;
  width: 0;
  padding: 0;
  margin: 0;
  display: none;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search:disabled {
  background: var(--wl-button-disabled-background);
  color: var(--wl-text-color-disabled);
  cursor: not-allowed;
}

.qs-block .qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container > label {
  white-space: nowrap;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location {
  display: block;
  margin-top: 8px;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .year,
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .price,
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--wl-text-color-twelve);
  padding: 11px;
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--wl-input-box-border-color);
  border-top: 1px solid var(--wl-input-box-border-color);
  border-bottom: 1px solid var(--wl-input-box-border-color);
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid var(--wl-input-box-border-color);
  border-top: 1px solid var(--wl-input-box-border-color);
  border-bottom: 1px solid var(--wl-input-box-border-color);
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.active {
  color: var(--wl-text-color-four);
  background-color: var(--wl-background-color-nineteen);
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show .icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(/static/media/chevron-more.a7b07650.svg) no-repeat;
  vertical-align: middle;
  margin-left: 4px;
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show .icon.collapse {
  background: url(/static/media/chevron-less.051ba6c3.svg) no-repeat;
}

#class-dropdown:disabled {
  background-color: rgba(233, 233, 233, 0.30);
}

.qs-content-body {
  font-size: var(--wl-font-size-medium);
}

.qs-content-body .ad {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 375px) {
  .qs-content-element {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

.qs-content-element.qs-block {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  padding: 20px 10px;
}

@media screen and (max-width: 767px) {
  .qs-content-body:nth-child(4) > div:not(:nth-child(2)) {
    max-width: 100%;
    width: 100%;
  }

  .qs-block {
    display: flex;
    background: #F2F2F2;
  }

  .qs-block .qs-filters {
    width: 100%;
  }
  .qs-block .qs-filters .quick-search-container {
    display: block;
  }
  .qs-block .qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    width: 100%;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location div.dropdown-container, .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location div.input-field-container {
    display: block;
    margin-bottom: 8px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.min,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.max {
    margin-right: 4px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms {
    margin-bottom: 8px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search {
    display: block;
    margin-top: 16px;
  }
  .qs-filters .quick-search-container .quick-search .bottom-row .qs-category-advanced-search,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show {
    border-bottom: 0px;
    min-width: 100%;
    display: block;
    text-align: center;
    margin-top: 12px;
    font-weight: 400;
  }
  .qs-content-element.qs-block {
    display: block;
  }
  .qs-ad-flex {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) {
  #quick-search-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .qs-block {
    display: flex;
  }

  .qs-block .qs-filters {
    flex: 1 1;
    height: var(--wl-quicksearch-t-height);
  }

  .qs-block .qs-filters .quick-search-container {
    height: 100%;
  }

  .qs-block .qs-filters .quick-search-container .quick-search {
    position: static;
    width: 100%;
    margin-right: 0;
    height: auto;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "condition class makemodel" "price year length" ". . search";
    grid-column-gap: 16px;
    column-gap: 16px;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location.engines {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "condition year price" ". . search";
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-makemodel {
    grid-area: makemodel;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-class {
    grid-area: class;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-condition {
    grid-area: condition;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length {
    grid-area: length;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .year {
    grid-area: year;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .price {
    grid-area: price;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search {
    grid-area: search;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.min,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.max {
    margin-right: 8px;
  }

  .qs-block .qs-ad-flex {
    display: flex;
    flex: 0 0 300px;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
  }
}

@media screen and (min-width: 960px) {
  .qs-block .qs-filters {
    height: var(--wl-quicksearch-d-height);
  }

  .qs-block .qs-ad-flex {
    position: static;
    width: 100%;
    margin-right: 0;
    margin-top: 0;
    height: auto;
    display: flex
  }
}

/*# sourceMappingURL=qs-alt-styles.css.map */

.input-field-container {
  position: relative;
}

.input-field-label {
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-small);
}

.input-field-label-error {
  color: var(--wl-background-color-eight);
}

.input-field-label-success {
  color: var(--wl-text-color-twelve);
}

.input-field-input {
  background: transparent;
  border: none;
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-medium);
  padding: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}

.input-field-input-success,
.input-field-input-error
{
  padding-right: 30px;
}

.input-field-input-active {
  color: var(--wl-text-color-default);
}

.input-field-input-container {
  background: var(--wl-brand-color-three);
  border: 1px solid var(--wl-input-box-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 43px;
  width: 100%;
}

.input-field-input-container:after{
  background-origin: content-box;
  background-repeat: no-repeat;
  height: 24px;
  padding: 10px;
  position: absolute;
  pointer-events: none;
  right: 0;
  width: 24px;
}

.input-field-input-container-error:after{
  background-image: url(/static/media/cancel.b2fd5618.svg);
  content: '';

}

.input-field-input-container-success:after{
  background-image: url(/static/media/check_circle.43e4f496.svg);
  content: '';
}

.input-field-input-container-error {
  border-color: var(--wl-background-color-eight);
}

.input-field-input-container-success {
  border-color: var(--wl-background-color-seven);
}

.input-field-message {
  color: var(--wl-text-color-twelve);
  display: block;
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-default);
  position: absolute;
}

.input-field-message-error {
  color: var(--wl-background-color-eight);
}

.input-autocomplete-container .autocomplete-options {
  background-color: var(--wl-brand-color-three);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  margin-right: 16px;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button {
  display: block;
  background-color: var(--wl-brand-color-three);
  border: 0px solid var(--wl-brand-color-three);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  padding: 8px 12px;
  text-align: initial;
  transition-duration: 0.4s;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled],
.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled]:focus,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button[disabled]:hover {
  background-color: inherit;
  color: inherit;
  cursor: default;
}

.input-autocomplete-container .autocomplete-options .autocomplete-option-button:focus,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button:hover,
.input-autocomplete-container .autocomplete-options .autocomplete-option-button.selected {
  color: var(--wl-text-color-four);
  background-color: var(--wl-brand-color-one);
}

.input-autocomplete-container.input-makemodel .autocomplete-options {
  max-height: 284px;
  overflow-y: auto;
}

#ad-pfba .adslot-target {
  display: block;
}

.ad-two-hero {
  height: 100%;
}

.pfba {
  max-width: var(--wl-max-header-width);
  position: relative;
  max-height: 500px;
  margin: 0 auto;
  aspect-ratio: 1440 / 500;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .pfba {
    padding-bottom: 94px;
  }
}

.hero {
  margin: 0 auto;
  max-height: 345px;
  max-width: var(--wl-max-header-width);
}

/*PFBA Image Resize*/
#div-gpt-ad-PFBA iframe{
  min-width: 100%!important;
  width: 1px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

#div-gpt-ad-PFBA div[id^="google_ads_iframe_"]{
  width: 100% !important;
  height: 100% !important;
}

.hero-text-title {
  font-weight: 700;
}

.hero .hero-image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.hero-elements-logo {
  object-fit : contain;
  max-width: 100px;
  padding: 10px;
}

.hero-elements .hero-elements-button {
  color: inherit;
  text-decoration: none;
}

/* Setting min-width to 0 in order to prevent children text fields from overflowing */
.hero-elements {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-right: 4px;
  min-width: 0;
  padding: 10px;
}

a.hero-elements-button::after {
  background-image: url(/static/media/Link.47ad2182.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  content: "";
  display: inline-block;
  height: 18px;
  margin-left: 4px;
  vertical-align: middle;
  width: 18px;
}

@media screen and (min-width: 500px) {
  .hero {
    max-height: 100%;
  }

  .hero .hero-image {
    height: 300px;
  }

  .hero-elements {
    margin-right: 16px;
  }
  
}

@media screen and (min-width:768px) {
  .hero {
    max-height: none;
  }

  .hero .hero-image {
    height: 350px;
  }

  .hero-elements {
    line-height: var(--wl-line-height-large);
  }
  
}

@media screen and (min-width:970px) {

  .hero {
    display: block;
    height: 500px;
    position: relative;
  }

  .hero .hero-image {
    height: 500px;
  }
}

.default-image-wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.default-image-centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-image-text {
  text-transform: uppercase;
  font-size: var(--wl-font-size-medium);
  color: var(--wl-text-color-five);
  white-space: nowrap;
}

.default-image-icon {
  background-image: url(/static/media/photo_camera.f56222d7.svg);
  background-size: contain;
  margin-bottom: 4px;
}

a .default-image-wrapper {
  position: inherit;
}

@media screen and (min-width: 768px) {
  .default-image-text {
    font-size: var(--wl-font-size-x-large);
  }
}

.video-tour-form-container {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--wl-background-color-ten);
}

#video-tour-form {
  box-shadow: 0 5px 15px var(--wl-box-shadow-two);
  position: relative;
  background-color: var(--wl-content-background-color-default);
  padding: 10px;
  width: 60%;
}

#video-tour-form > button.contact-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: auto;
  border: none;
  font-weight: 0;
  font-size: var(--wl-font-size-xx-large);
  z-index: 1;
  color: var(--wl-text-color-twelve);
}

.icon-x:before {
    content: "x";
}

#video-tour-form > button.contact-close > .aria-fixes {
  display: none;
}

#video-tour-form .main-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

#video-tour-form .main-form .title {
  color: var(--wl-text-color-link);
  font-weight: 700;
  margin: 5px 0;
}

#video-tour-form .main-form .subtitle {
  font-weight: 400;
  font-size: var(--wl-font-size-xx-small);
  color: var(--wl-text-color-link);
  margin-bottom: 10px;
}

#video-tour-form .submit-container {
  padding: 15px;
  margin-top: 10px;
}

#video-tour-form .main-form button.btn-action {
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding: 12px 0;
  line-height: var(--wl-line-height-medium);
  font-size: var(--wl-font-size-small);
  font-weight: 700;
  background-color: var(--wl-background-color-one);
  color: var(--wl-text-color-four);
}

#video-tour-form fieldset {
  border: none;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

#video-tour-form input[type="text"] {
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid var(--wl-border-color-seven);
  height: 2.4em;
  width: 100%;
  z-index: 2;
  padding: 0.5em 10px;
  box-shadow: none;
}

#video-tour-form .main-form .message-error {
  color: var(--wl-text-color-nine);
  clear: both;
  padding: 15px 0;
  text-align: left;
  font-size: var(--wl-font-size-xx-small);
}

#video-tour-form .message-sent {
  color: var(--wl-text-color-link);
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
  display: flex;
  min-height: 200px;
  margin: 40px;
}

#video-tour-form .message-sent-error {
  border: 1px solid var(--wl-border-color-default);
  border-radius: 5px;
  display: flex;
  min-height: 200px;
  margin: 40px;
}

#video-tour-form .message-box {
  color: var(--wl-text-color-link);
  padding: 15px;
}

#video-tour-form .message-box .message-title {
  font-size: var(--wl-font-size-h3);
  font-weight: 700;
}

#video-tour-form .message-sent p,
#video-tour-form .message-sent-error p {
  margin-top: 0;
  font-size: var(--wl-font-size-small);
}

@media screen and (min-width:1025px) {
  #video-tour-form {
    width: 30%;
  }
}

.tooltip-modal-actionable > div  {
  background: unset !important;
}

.tooltip-modal-actionable > div > div {
  background: unset !important;
  border: unset !important;
}

.monthly-price {
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 0.934rem;
  color: #119BF5;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  z-index: 1000;
}

.monthly-price .info-icon {
  padding-left: .35rem;
  padding-top: 2px;
}

.monthly-price .info-icon > img{
  width: 14px;
  height: 14px;
}

.tooltip-wrapper .react-tooltip-lite {
  max-width: 232px !important;
  height: 174px;
  background: none;
  margin: -1px;
  padding: unset !important;
  margin-left: 10px;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  background: #35495D;
  border: 1px solid #EDEDED;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: auto;
  color: #EDEDED;
  font-family: 'Lato', sans-serif;
  padding: 16px 12px;
  grid-gap: 8px;
  gap: 8px;
  margin-left: 12px;
}

.tooltip-wrapper .tooltip-monthly .tooltip-monthly-head {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-head-monthlyPayment {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFF;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close {
  cursor: pointer;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img {
  width: 12px;
  opacity: .8;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly  .tooltip-monthly-head-close > img:hover {
  opacity: 1;
}

.tooltip-wrapper .react-tooltip-lite .tooltip-monthly .tooltip-monthly-body .tooltip-monthly-body-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #F5F5F5;
  margin: 0;
  padding-bottom: 8px;
}


/* Mobile */
@media screen and (max-width: 767px) {

  .tooltip-wrapper {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .tooltip-wrapper .react-tooltip-lite {
    height: unset !important;
    width: auto !important;
    z-index: 1000 !important;
    max-width: 51% !important;
    margin-left: unset !important;
  }

  .tooltip-wrapper .monthly-price{
    justify-content: flex-start;
    margin-bottom: 5px;
    font-size: 12px !important;
    font-family: 'Roboto';
    font-weight: 700;
  }

  .tooltip-wrapper .tooltip-monthly {
    background: #FFFFFF !important;
    box-shadow: -10px 4px 30px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 5px 9px !important;
    
  }

  .tooltip-wrapper .react-tooltip-lite + .react-tooltip-lite-arrow {
    border-color: #FFFFFF;
  }

  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-head-monthlyPayment {
    color: #404040 !important;
    font-size: 10px !important;
  }
  
  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-body-paragraph {
    font-family: 'Roboto' !important;
    color: #404040 !important;
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .tooltip-wrapper.right:not(.enhanced) .tooltip-monthly {
    margin-top: -45px;
    max-width: 170px;
  }
  .tooltip-wrapper.right:not(.enhanced) .react-tooltip-lite-right-arrow {
    margin-top: -25px;
  }

  .tooltip-wrapper.enhanced {
    float: right;
  }

  .tooltip-wrapper .react-tooltip-lite .tooltip-monthly {
    margin-left: 0px;
  }

  .tooltip-wrapper .tooltip-monthly .tooltip-monthly-head {
    margin-bottom: -7px;
  }
  
}
.listing-result {
  width: 100%;
  background: var(--wl-content-background-color-default);
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-right: 10px;
  position: relative;
  list-style: none;
  border: 1px solid var(--wl-border-color-default);
}

.listing-result .inner {
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
}

.search-results .boat-list .listing-result {
  border: none;
}
.search-results .boat-list .listing-result .inner {
  border-radius: 8px;
  border: 1px solid var(--wl-border-color-default);
}

.listing-result .image-container {
  position: relative;
  overflow: hidden;
  width: 45%;
  max-width: 253px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.search-results .boat-list.boat-list-results .listing-result .image-container {
  overflow: visible;
}

.listing-result .image-container .dummy {
  padding-top: 66.666666666666%; /* 4:3 aspect ratio */
}

.listing-result .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.listing-result .image-container .image .image-results {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.listing-result .image-container .image .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}

.listing-result .image-container .preloader {
  z-index: 3;
  background: var(--wl-preload-background-image);
  height: 100%;
  width: 100%;
}

.listing-result .image-container .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

.listing-result .description {
  color: var(--wl-text-color-five);
  text-align: center;
  position: relative;
  width: 55%;
  line-height: 1;
  flex-grow: 1;
  font-family: var(--wl-font-family-default);
  font-style: normal;
  font-weight: normal;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.listing-result .top {
  float: left;
  width: 100%;
}

.listing-result .bottom {
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.listing-result .name {
  float: left;
  clear: both;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-seven);
  overflow: hidden;
  text-align: left;
  margin-bottom: 3px;
  max-height: 80px;
  max-width: 100%;
}

.listing-result-enhanced .name {
  max-width: 50%;
}

.listing-result .name h2 {
  margin: 0;
  font-weight: normal;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.listing-result a .name {
  color: var(--wl-text-color-seven);
}

.listing-result .price {
  float: left;
  clear: both;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  margin-bottom: 3px;
  color: var(--wl-price-text-color);
  text-align: left;
}

.listing-result .location {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-xxx-small);
  color: var(--wl-text-color-two);
  float: left;
  clear: both;
  margin-bottom: 3px;
}

.listing-result a .location {
  color: var(--wl-text-color-five);
}

.listing-result .offered-by {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-seven);
  float: left;
  clear: both;
  text-align: left;
  align-items: flex-end;
  flex-grow: 1;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-result a .offered-by {
  color: var(--wl-text-color-seven);
}

.listing-result-enhanced .contact {
  position: relative;
}

.listing-result-enhanced .contact-seller {
  background: var(--wl-background-color-one);
  bottom: 9.44px;
  border-radius: 4px;
  border: 0 solid;
  cursor: pointer;
  color: var(--wl-text-color-four);
  float: right;
  font-size: var(--wl-font-size-x-small);
  font-weight: 700;
  line-height: var(--wl-line-height-default);
  padding-bottom: 8px;
  padding-top: 8px;
  right: 10px;
  width: 102px;
  z-index: 4;
}

.listing-result-enhanced .dealer {
  display: grid;
  width: 80%;
  min-height: 90px;
  align-items: end;
  grid-template-rows: 1fr auto;
}

.listing-result-enhanced .dealer-logo {
  max-height: 40px;
  max-width: 100px;
}

.listing-result-enhanced .description {
  width: auto;
}

.listing-result-enhanced .image-container {
  max-width: 100%;
  width: auto;
}

.listing-result-enhanced .image-container .dummy {
  padding-top: 46%;
}

.listing-result-enhanced .inner {
  flex-direction: column;
}

.listing-result-enhanced .name {
  font-size: var(--wl-font-size-large);
  font-weight: bold;
  line-height: var(--wl-line-height-default);
}

.listing-result-enhanced .offered-by {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
}

.listing-result-enhanced .price{
  font-size: var(--wl-font-size-x-large);
  font-weight: bold;
  line-height: var(--wl-line-height-default);
}

.listing-result-enhanced .sold-by {
  color: var(--wl-text-color-two);
}

@media screen and (min-width: 768px) {
  .listing-result .name {
    clear: none;
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
    max-width: 48%;
  }

  .listing-result .name h2 {
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
  }

  .listing-result .price {
    clear: none;
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
    float: right;
  }

  .listing-result .location {
    font-size: var(--wl-font-size-xx-small);
    line-height: var(--wl-line-height-xx-small);
  }

  .listing-result .offered-by {
    font-size: var(--wl-font-size-xx-small);
    line-height: var(--wl-line-height-small);
  }

  .listing-result-enhanced .dealer-logo {
    max-height: 60px;
    max-width: 155px;
  }

  .listing-result-enhanced .image-container {
    width: 45%;
  }

  .listing-result-enhanced .image-container .dummy {
    padding-top: 66.666666666666%; /* 4:3 aspect ratio */
  }

  .listing-result-enhanced .inner {
    flex-direction: row;
  }

  .listing-result-enhanced .name {
    font-size: var(--wl-font-size-x-large);
  }

  .listing-result-enhanced .offered-by {
    font-size: var(--wl-font-size-x-small);
    line-height: var(--wl-line-height-small);
  }
}

.icons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 18px 8px 8px 8px;
  box-sizing: border-box;
  background: linear-gradient(0deg,rgba(0,0,0,.7),transparent);
  z-index: 10;
}
.icons > div {
  transform: scale(1.35);
}
.icons > div + div {
  margin-left: 10px;
}
.icons > div:hover .icon-tooltip {
  display: block;
}

.icons .icon-tooltip {
  font-size: var(--wl-font-size-x-small);
  color: #ececec;
  transform: scale(.65);
  transform-origin: left bottom;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  background: #2d3e4f;
  padding: 8px 14px 9px;
  position: absolute;
  bottom: 20px;
  display: none;
}
.icon-tooltip:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #2d3e4f;
  position: absolute;
  bottom: -11px;
  left: 3px;
}

.listing-card-image-cpyb {
  background: url(/static/media/cpyb.211acfa5.svg) no-repeat;
  height: 10px;
  width: 19px;
}
.listing-card-image-cpyb .icon-tooltip {
  left: -2px;
  width: 320px;
  white-space: pre-wrap;
}
.listing-card-image-video {
  background: url(/static/media/video-outline.738b6864.svg) no-repeat;
  height: 12px;
  width: 15px;
  background-position: 0 -2px;
  background-size: 15px 15px;
}
.listing-card-image-video .icon-tooltip {
  left: -3px;
}
.listing-card-image-360 {
  background: url(/static/media/360-listing.3324d6c5.svg) no-repeat;
  height: 10px;
  width: 14px;
}
.listing-card-image-360 .icon-tooltip {
  left: -3px;
}
.listing-card-image-delivery {
  background: url(/static/media/localDelivery-listing.f457f57b.svg) no-repeat;
  height: 10px;
  width: 19px;
}
.listing-card-image-delivery .icon-tooltip {
  left: 0px;
}

.banner-attribute {
  position: absolute;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  line-height: 20px;
}
.banner-attribute.IN_STOCK {
  background: #00AE48;
}
.banner-attribute.AVAILABLE_SOON {
  background: #E37900;
}
.banner-attribute.SALE_PENDING {
  background: #E4B700;
}
.banner-attribute.NEW_ARRIVAL {
  background: #008BE5;
}

.listing-result:not(.listingStyleDefault) .banner-attribute,
.listing-result:not(.listingStyleDefault) .icons {
  display: none;
}

.listing-result.listingStyleDefault .name h2 {
  font-size: var(--wl-font-size-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  max-width: 270px;
  overflow: hidden;
}
.listing-result.listingStyleDefault .location {
  line-height: var(--wl-line-height-medium);
  text-align: left;
}
.listing-result.listingStyleDefault:not(.isHome) .location {
  position: absolute;
  left: 10px;
  top: 32px;
}
.listing-result.listingStyleDefault .location > div {
  margin-top: 3px;
}
.listing-result-enhanced.listingStyleDefault .location > div {
  margin-top: 2px;
}
.listing-result.listingStyleDefault.isHome .location > div,
.listing-result-enhanced.listingStyleDefault.isHome .location > div {
  display: none;
}
.listing-result.listingStyleDefault .price {
  font-size: var(--wl-font-size-medium);
  text-align: right;
}
.listing-result.listingStyleDefault .price > div {
  font-size: var(--wl-font-size-xx-small);
}

.listing-result.listingStyleDefault .contact-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.listing-result.listingStyleDefault .contact + .contact {
  margin-top: 8px;
}
.listing-result.listingStyleDefault .contact-seller,
.listing-result.listingStyleDefault .live-video-tour {
  font-size: var(--wl-font-size-medium);
  background: #2980B9;
  border-radius: 50px;
  width: 150px;
}
.listing-result.listingStyleDefault .live-video-tour {
  bottom: 9.44px;
  cursor: pointer;
  color: var(--wl-text-color-four);
  float: right;
  font-weight: 700;
  line-height: var(--wl-line-height-default);
  padding-bottom: 8px;
  padding-top: 8px;
  right: 10px;
  z-index: 4;
  background: #ffffff;
  color: #2980B9;
  border: 1px solid #2980B9;
  box-sizing: border-box;
}
.listing-result.listingStyleDefault .contact-seller div {
  background: url(/static/media/envelope-mail.18b7340e.svg) no-repeat;
  width: 18px;
  height: 11px;
}
.listing-result.listingStyleDefault .live-video-tour div {
  background: url(/static/media/video-icon.de89b0d1.svg) no-repeat;
  width: 16px;
  height: 11px;
}

.listing-result.listingStyleDefault .dealer-logo {
  width: 75px;
  margin-bottom: 10px;
}
.listing-result.listing-result-enhanced.listingStyleDefault .description {
  background: #E7F4F9;
}

.listing-result.listingStyleDefault .manufacturer-tag {
  border-radius: 12px 0 0 12px;
  background: linear-gradient(45deg,#F1860D,#C15925);
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  position: absolute;
  top: 15px;
  right: 0;
  width: 200px;
}
.listing-result.listing-result-enhanced.listingStyleDefault .manufacturer-tag {
  display: none;
}

@media screen and (min-width: 768px) {
  .listing-result.listing-result-enhanced.listingStyleDefault:not(.isHome) .image-container {
    width: 300px;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault:not(.isHome) .image-container {
    width: 240px;
  }
}

@media screen and (max-width: 767px) {
  .listing-result.listing-result-enhanced.listingStyleDefault .price {
    float: none;
    clear: none;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .location {
    position: relative;
    top: 0;
    left: 0;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .price {
    text-align: left;
  }

  .listing-result.listingStyleDefault .contact-area {
    flex-direction: row;
  }
  .listing-result.listingStyleDefault .contact-seller,
  .listing-result.listingStyleDefault .live-video-tour {
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .live-video-tour {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }
  .listing-result.listingStyleDefault .contact + .contact {
    margin-top: 0;
    margin-left: 8px;
  }

  .listing-result.listing-result-enhanced.listingStyleDefault .description {
    height: 133px;
  }

  .listing-result.listingStyleDefault .manufacturer-tag {
    bottom: 0;
    top: initial;
    border-radius: 0;
    width: 100%;
    font-size: var(--wl-font-size-xxx-small);
    line-height: 14px;
  }
  .listing-result.listingStyleDefault h2.manufacturer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
  }
  .listing-result.listingStyleDefault .dealer.listingStyleDefault-manufacturer .dealer {
    height: 50px;
  }
  .listing-result.listingStyleDefault .dealer.listingStyleDefault-manufacturer .dealer-logo {
    width: 50px;
    margin-bottom: 8px;
    margin-top: 5px;
    vertical-align: bottom;
  }

  .banner-attribute {
    font-size: 10px;
    line-height: 14px;
  }

  .listing-result-enhanced .dealer {
    min-height: 65px;
  }
}
.listing-result.listing-result-card {
  background-color: var(--wl-content-background-color-default);
  font-family: var(--wl-font-family-default);
  color: var(--wl-text-color-one);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 24px;
  max-width: 100%;
  flex: 1 0 100%;
  border: 0;
}

.listing-result.listing-result-card .inner {
  flex-direction: column;
}

.listing-result.listing-result-card .image-container {
  width: 100%;
  max-width: initial;
}

.listing-result.listing-result-card .image-results {
  border-radius: 4px;
}

.listing-result.listing-result-card .description {
  padding: 0;
  margin-top: 6px;
  width: auto;
}

.listing-result.listing-result-card .name {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  font-weight: bold;
  max-width: initial;
  margin: 2px 0;
}

.listing-result.listing-result-card .price {
  line-height: var(--wl-line-height-default);
  font-size: var(--wl-font-size-medium);
  color: var(--wl-price-text-color);
  font-weight: bold;
  text-align: left;
  float: initial;
  margin: 2px 0;
  clear: both;
}

.listing-result.listing-result-card .location {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
  text-transform: uppercase;
  color: var(--wl-text-color-twelve);
  margin: 2px 0 0;
}

@media screen and (min-width: 375px) {
  .listing-result.listing-result-card {
    max-width: calc(50% - 24px);
    margin-left: 24px;
  }

  .listing-result.listing-result-card .name {
    font-size: var(--wl-font-size-small);
    line-height: var(--wl-line-height-default);
  }
}

@media screen and (min-width: 768px) {
  .listing-result.listing-result-card {
    max-width: calc(33% - 24px);
  }
}

@media screen and (min-width: 970px) {
  .listing-result.listing-result-card {
    max-width: calc(25% - 24px);
  }

  .listing-result.listing-result-card .name {
    font-size: var(--wl-font-size-medium);
    line-height: var(--wl-line-height-default);
  }
}


.nearby-listings-message-container {
  border: 1px solid var(--wl-border-color-default);
  margin-bottom: 15px;
  display: flex;
}

.nearby-listings-message-arrow {
  background-image: url(/static/media/SimilarListingsArrowIcon.4d60c504.svg);
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  width: 60px;
}
ol.boat-list {
  margin: 12px 0;
  padding: 0;
  list-style: none;
}

ol.boat-list:after {
  display: table;
  content: '';
  clear: both;
}

ol.boat-list li.desktop-liner-ad,
ol.boat-list li.mobile-liner-ad {
  border: 0px none;
  text-align: center;
}

#SearchResults .stickyLeaderboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 315px;
  box-sizing: border-box;
  z-index: 1000;
}
#SearchResults .stickyLeaderboard #div-gpt-ad-leaderboard-alpha {
  margin: 0;
}
#SearchResults .main-footer {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .mobile-liner-ad.premium.bp1.ad-320x80 {
    min-height: 265px;
  }
}

@media screen and (max-width: 767px) {
  li.lib-card {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

.loading-container .boat-list.loading {
  display: block;
  width: 100%;
}

.loading-container .lib-card {
  height: 350px;
}

.loading-container .lib-card .inner {
  position: relative;
  overflow: hidden;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, .2));
  transform: translateZ(0);
  height: 100%;
}

.loading-container .lib-card .image-container {
  min-width: 100%;
  position: relative;
  overflow: hidden;
  width: 45%;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.loading-container .lib-card .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.loading-container .lib-card .image-container .image .preloader {
  z-index: 3;
  background: var(--wl-preload-background-image);
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}

@media screen and (min-width: 768px) {
  ol.boat-list li.desktop-liner-ad {
    width: 100%;
    margin: 0 auto 12px;
    text-align: center;
  }
  .loading-container .boat-list.loading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
    align-items: stretch;
    align-content: start;
    width: 100%;
  }
}

.boat-list.boat-list-home {
  background-color: var(--wl-content-background-color-default);
  display: flex;
  flex-direction: column;
  margin: 0 0 -8px;
}

@media screen and (min-width: 375px) {
  .boat-list.boat-list-home {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -24px;
  }
}

.homepage-quicklinks-container {
  color: var(--wl-text-color-ten);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: var(--wl-max-content-width);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.homepage-quicklinks-container .collapse-content-details {
  width: 100%;
}

.homepage-quicklinks-container .collapse-content-details .header a {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.homepage-quicklinks-container .quicklink-list {
  display: flex;
  flex-basis: 25%;
  justify-content: flex-start;
  width: 100%;
}

.homepage-quicklinks-container .quicklink-list h2 {
  color: var(--wl-text-color-one);
  font-weight: bold;
  font-size: var(--wl-font-size-large);
  margin-top: 32px;
  padding: 0;
}

.homepage-quicklinks-container div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.homepage-quicklinks-container .quicklink-list ul {
  margin: 0px;
  padding-inline-start: 0;
}

.homepage-quicklinks-container .quicklink-list li {
  list-style: none;
}

.homepage-quicklinks-container .quicklink-list li a {
  color: var(--wl-text-color-ten);
  line-height: var(--wl-line-height-x-large);
}

.homepage-quicklinks-container .quicklink-list li a:hover,
.homepage-quicklinks-container .quicklink-list li a:focus {
  color: var(--wl-text-color-eleven);
  text-decoration: underline;
}

.homepage-quicklinks-container .collapse-content-quicklist {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .homepage-quicklinks-container {
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  .homepage-quicklinks-container .quicklink-list {
    display: flex;
    width: 50%;
  }
}

@media screen and (min-width: 970px) {
  .homepage-quicklinks-container {
    flex-wrap: nowrap;
  }

  .homepage-quicklinks-container .quicklink-list {
    display: flex;
    justify-content: flex-start;
    width: auto;
  }
}

/** Quick links redesign */
.home-quicklinks-container {
  color: var(--wl-text-color-thirteen);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: var(--wl-font-size-medium);
  justify-content: space-evenly;
  line-height: var(--wl-line-height-medium);
  margin: 0 auto;
  max-width: var(--wl-max-content-width);
  padding: 0 0 32px;
}

.home-quicklinks-container h2 {
  margin-left: 16px;
  margin-top: 32px;
}

.home-quicklinks-container .collapse-content {
  border-bottom: 1px solid var(--wl-border-color-default);
  margin: 0;
}

.home-quicklinks-container .collapse-content .header {
  margin: 0;
}

.home-quicklinks-container .collapse-content.open .header {
  background: var(--wl-border-color-eleven);
  opacity: 0.5;
}

.home-quicklinks-container .collapse-content .header {
  font-size: 18px;
}

.home-quicklinks-container .collapse-content .header a {
  color: var(--wl-text-color-thirteen);
  font-weight: normal;
}

.home-quicklinks-container .quicklink-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-quicklinks-container .quicklink-list li {
  display: inline-block;
  flex-basis: 100%;
  padding: 8px;
}

.home-quicklinks-container .quicklink-list a {
  color: var(--wl-text-color-thirteen);
}

@media screen and (min-width: 768px) {
  .home-quicklinks-container .quicklink-list li {
    flex-basis: 47%;
  }

  .home-quicklinks-container h2 {
    margin-left: 0;
  }
}

@media screen and (min-width: 970px) {
  .home-quicklinks-container .quicklink-list li {
    flex-basis: 31%;
  }
}

.main-fsbo-ad {
  width: calc(100% + 20px);
  background-color: var(--wl-background-color-default);
  margin-top: 20px;
  margin-left: -10px;
  justify-content: space-between;
  padding: 24px 0;
  margin-bottom: 40px;
}

.main-fsbo-ad .left {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-fsbo-ad .left .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-xx-large);
  margin-bottom: 17px;
  font-weight: bold;
}

.main-fsbo-ad .left .fsbo-text {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
  color: var(--wl-text-color-six);
}

.main-fsbo-ad .left a {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
  font-weight: bold;
  color: var(--wl-text-color-four);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: var(--wl-background-color-one);
  border-radius: 4px;
  cursor: pointer;
}

.main-fsbo-ad .left .fsbo-link {
  display: flex;
  margin-top: 17px;
}

.main-fsbo-ad .right {
  display: flex;
  height: 110px;
  justify-content: flex-end;
  margin: auto;
  overflow: visible;
  padding: 0 16px;
}

.main-fsbo-ad .right .fsbo-image {
  background-position: 53% 69%;
  background-size: 130%;
  border-radius: 4px;
  height: 130%;
  margin-top: 23px;
  width: 100%;
}

@media screen and (min-width: 375px) {
  .main-fsbo-ad {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
  .main-fsbo-ad .right {
    height: 120px;
  }
  .main-fsbo-ad .right .fsbo-image {
    background-position: 53% 66%;
  }
}

@media screen and (min-width: 768px) {
  .main-fsbo-ad {
    display: flex;
  }

  .main-fsbo-ad .right {
    height: 100px;
    width: 100%;
    align-items: center;
  }

  .main-fsbo-ad .left {
    padding-right: 0;
    min-width: 368px;
  }

  .main-fsbo-ad .right .fsbo-image {
    height: 279px;
    margin-top: 0;
  }
}

@media screen and (min-width: 970px) {
  .main-fsbo-ad {
    margin-left: 0;
    width: 100%;
  }

  .main-fsbo-ad .left {
    padding-left: 40px;
  }

  .main-fsbo-ad .right .fsbo-image {
    width: 100%;
    max-width: 735px;
    height: 273px;
    background-size: 735px;
    background-position: 25% 75%;
    margin-right: 4%;
  }
}

.main-content-body {
  font-size: var(--wl-font-size-medium);
}

.main-content-element {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  padding: 20px 10px;
}

#featured_boats {
  background: initial;
}
#featured_boats > div {
  padding-bottom: 0;
}

.ad.w728:empty {
  display: none;
}

.hero.premium-featured-ad .ad#div-gpt-ad-PFBA {
  height: 0px;
}

.information-content {
  width: 100%;
  background-color: var(--wl-background-default-footer);
  color: var(--wl-text-color-ten);
  padding: 0 16px;
  box-sizing: border-box;
}

.information-content .collapse-content .header h3 {
  margin: 0;
  cursor: pointer;
}

.information-content .collapse-content > .header span a .icon, .information-content .collapse-content > .header h3 a .icon {
    background: url(/static/media/DownArrow.43426a2f.svg) no-repeat;
}

.information-content .collapse-content .header h3 > a {
  font-size: var(--wl-font-size-medium);
}

.homepage-seo-content {
  max-width: var(--wl-max-content-width);
  line-height: var(--wl-line-height-medium);
  font-size: var(--wl-font-size-medium);
  margin: 0 auto;
  padding: 24px;

}

.homepage-seo-content a {
  color: var(--wl-text-color-ten);
  text-decoration: underline;
}
.homepage-seo-content a:hover,
.homepage-seo-content a:focus {
  color: var(--wl-text-color-eleven);
}

.home-block .homepage-seo-content a:hover, .home-block .homepage-seo-content a:focus {
  color: var(--wl-background-color-nineteen);
}

#Home.page-container .hero {
  position: relative;
  width: 100%;
  height: auto;
}

#Home.page-container .hero.premium-featured-ad,
#Home.page-container .hero.with-ad {
  overflow-y: hidden;
  /* we want the height of hero container without ad to be the same as the google hero ad. 
  And the height changed proportionally when the screen width changed to fix CLS issue */
  height: calc(100vw * 0.3417);
}

@media screen and (min-width: 375px) {
  .main-content-element {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

.fsbo-mini-ad {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 16px 15px;
  text-align: center;
}

.fsbo-mini-ad a {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  font-weight: bold;
  color: var(--wl-text-color-eleven);
  padding: 8px 16px;
  margin-left: 16px;
  background: var(--wl-content-background-color-default);
  border-radius: 4px;
  border-color: var(--wl-border-color-eight);
  border: 2px solid;
  cursor: pointer;
}

.latest-boats {
  background-color: var(--wl-content-background-color-default);
}

.latest-boats .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
  margin: 16px 0;
}

.latest-boats .view-all-boats {
  font-family: var(--wl-font-family-default);
  font-weight: bold;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: var(--wl-text-color-one);
  background-image: url(/static/media/ArrowForward.d96cbdba.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center right;
  padding-right: 18px;
}

.private-featured {
  background-color: #F2F2F2;
  margin: 30px 0px;
}

.private-featured .main-content-element {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: calc(var(--wl-max-content-width) - 3%);
}

.private-featured .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
}

.main-content-body .ad {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  #Home.page-container .hero.premium-featured-ad,
  #Home.page-container .hero.with-ad {
    padding-bottom: 100px;
  }
}


@media screen and (max-width: 769px) {
  .main-content-body:nth-child(4) > div:not(:nth-child(2)) {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 769px) {

  .main-content-body:nth-child(4) > div:not(:nth-child(3)) {
    max-width: 100%;
    width: 100%;
  }

  .content.nav-slide.home-block .main-content-body .main-content-element.blog {
    max-width: var(--wl-max-content-width);
  }

  .qs-ad-content .ad {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .homepage-seo-content {
    margin-top: 24px;
  }

  .homepage-seo-content.block-editorial {
    max-width: var(--wl-max-content-width);
    margin-top: 0;
    padding-top: 0;
  }
}

@media screen and (min-width: 1440px) {
  #Home.page-container .hero.premium-featured-ad,
  #Home.page-container .hero.with-ad {
    height: 500px;
  }
}

.debug-message {
  display: none;
  height: 0;
}

/* Component */
.home-blog-section .blog-section-title {
  font-size: 29px;
  line-height: var(--wl-line-height-default);
  font-weight: 700;
  margin: 50px 0 12px 0;
}

.home-blog-section .blog-section-title.row-layout {
  margin: 0 0 10px 0;
}

.home-blog-section .blog-section-read-more {
  margin: 24px 0 40px 0;
}

.home-blog-section .blog-section-read-more.row-layout {
  margin: 0 !important;
  padding: 0 16px;
}

.home-blog-section .blog-section-read-more a {
  font-size: var(--wl-font-size-small);
  font-weight: 400;
  color: var(--wl-text-color-default)
}

.home-blog-section .blog-section-read-more.row-layout a {
  font-weight: 500;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: #3B96D2;
}

.blog-section-read-more.row-layout a:hover,
.blog-section-read-more.row-layout a:visited,
.blog-section-read-more.row-layout a:active {
  color: #3B96D2;
}

/* Articles */
.home-blog-section .article-title {
  font-weight: 700;
}

.home-blog-section .row-layout .article-title {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.home-blog-section .article-category {
  font-weight: 400;
}

.home-blog-section .row-layout .article-category {
  font-weight: 500;
  font-size: var(--wl-font-size-xx-small);
  line-height: var(--wl-line-height-xx-small);
}

.home-blog-section .article-text-container {
  box-sizing: border-box;
  font-size: var(--wl-font-size-x-small);
}

.home-blog-section .article-picture {
  object-fit: cover;
}

/* Main article */
.home-blog-section .home-main-article {
  position: relative;
}

.home-main-article .article-picture {
  position: relative;
  height: 140px;
  width: 100%;
}

.home-main-article .article-text-container {
  position: absolute;
  background-color: var(--wl-box-shadow-two);
  color: var(--wl-text-color-four);
  bottom: 4px;
  padding: 4px 6px;
  width: 100%;
}

.home-main-article .row-layout .article-text-container {
  padding: 0;
}

/* Secondary articles */
.home-blog-section .home-secondary-articles {
 display: grid;
 grid-row-gap: 8px;
 row-gap: 8px;
 margin: 8px 0;
}

.home-blog-section .row-layout .home-secondary-articles {
 padding: 1em 0;
}

.home-blog-section .row-layout .home-secondary-articles article{
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  margin: 0 0.6em 0 0;
}

.home-secondary-articles article{
  cursor: pointer;
}

.home-article .article-picture{
  display: none;
}

.home-article .article-text-container {
  box-sizing: border-box;
  padding: 4px 18px;
}

.home-article .article-title, .home-article .article-title a {
  color: var(--wl-blog-section-secondary-title-color);
}

.home-article .article-category {
  color: var(--wl-text-color-default);
}

.home-blog-section .row-layout .home-secondary-articles {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.home-blog-section .row-layout article .article-picture {
  display: block;
  width: 100%;
  height: 190px;
  border-radius: 5px;
}

.home-blog-section .row-layout .home-article .article-text-container {
  padding: 4px 16px;
}

.home-blog-section .row-layout .home-article .article-category {
  text-transform: uppercase;
  color: #3B96D2;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.home-blog-section .row-layout .home-article .article-title {
  margin: 10px 0;
}

.home-blog-section .row-layout .home-article .article-title a{
  color: #334556;
}

.home-blog-section .row-layout .home-article .article-excerpt {
  color: #686868;
  margin-bottom: 10px;
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-medium);
}

.row-layout article {
  flex: 1 0 75%;
  margin-right: 1rem;
}

.no-image {
  background: url(/static/media/photo_camera.f56222d7.svg) no-repeat center;
  height: 190px;
  width: 100%;
}


@media screen and (min-width:768px) {
  /* Component */
  .home-blog-section .blog-section-title {
    margin: 50px 0 36px 0;
  }

  .home-blog-section .blog-section-title.row-layout {
    margin: 0 10px 0 0;
  }

  .home-blog-section .blog-section-read-more {
    margin: 24px 0 14px 0;
  }

  .home-blog-section .blog-section-read-more.row-layout {
    margin: 20px 0;
  }

  /* Main article */
  .home-main-article .article-picture {
    height: 370px;
  }

  .home-main-article .article-text-container {
    padding: 8px 16px;
  }

  .home-main-article .article-title {
    font-size: var(--wl-font-size-large);
  }

  .home-main-article .article-category {
    font-size: var(--wl-font-size-medium);
  }

  /* Secondary Articles */

  .home-blog-section .home-secondary-articles {
    grid-gap: 24px 26px;
    gap: 24px 26px;
    margin: 25px 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 74px 74px;
  }

  .home-blog-section .home-article {
    display: flex;
  }

  .home-article .article-picture {
    display: block;
    height: 74px;
    min-width: 125px;
    max-width: 125px;
  }

  .row-layout .home-article .article-picture {
    display: block;
    height: 74px;
    min-width: none;
    max-width: none;
  }

  .home-article .article-text-container {
    width: auto;
    padding: 10px 16px;
  }

  /* row layout*/

  .home-blog-section .home-articles-container.row-layout {
    display: flex;
  }

  .home-blog-section .row-layout .home-article {
    display: block;
  }

  .home-blog-section .row-layout article {
    flex: 1 0 60%;
  }

  .home-blog-section .row-layout article .article-picture, .row-layout article .article-text-container {
    display: block;
  }
}

@media screen and (min-width:970px) {
  /* Component */
  .home-blog-section .blog-section-title.row-layout {
    margin: 0 0 10px 0;
  }

  .home-blog-section .blog-section-read-more {
    margin: 27px 0 11px 0;
  }

  .home-blog-section .blog-section-read-more.row-layout {
    margin: 10px 0;
  }

  .home-blog-section .home-articles-container {
    position: relative;
    display: grid;
    grid-column-gap: 24px;
    column-gap: 24px;
    grid-template-columns: 1fr 355px;
  }

  .home-blog-section .row-layout .home-secondary-articles {
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
  }

  .home-blog-section .home-secondary-articles {
    grid-row-gap: 24px;
    grid-template-rows: 74px 74px 74px 74px;
    grid-template-columns: 1fr;
    margin: 0;
  }

  .home-blog-section .row-layout article {
    flex: 1 0 20%;
  }
}

.forum-banner {
  background-color: var(--wl-background-color-eighteen);
  display: flex;
  flex-direction: column;

  background-image: 
    url(/static/media/ForumBanner.6f580a2e.svg),
    url(/static/media/Person.355ad065.svg);
  
  background-position: 
    top -20px left 10px,
    bottom right 10px;
  
  background-repeat: no-repeat;
}

.forum-banner .forum-banner-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 20px
}

.forum-banner-elements .forum-title {
  text-align: center;
  color: white;
  margin-right: 30px;
  font-size: 18px;
}

.forum-banner-elements .forum-button-container {
  background-color: var(--wl-background-color-seventeen);
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 13px 8px;
}

.forum-button-container .forum-button {
  text-decoration: none;
}

.forum-button-container .forum-button-text:before {
  content: url(/static/media/Forum.f697c7b8.svg);
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.forum-button-container .forum-button-text {
  color: var(--wl-text-color-sixteen);
}


@media screen and (max-width: 600px) {
  .forum-banner-elements .forum-title {
    text-align: left;
  }

  .forum-banner .forum-banner-elements {
    display:inline-block;
    flex-direction: row;
    height: 20%;
  }
  .forum-banner-elements .forum-button-container {
    margin-top: 20px;
  }
}
.social-wrapper {
  background-color: var(--wl-background-color-nineteen);
  display: block;
  padding: 0 16px;
  box-sizing: border-box;
}

.social-elements {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  justify-content: space-between;
  color: white;
  padding: 8px 16px 24px;
}

.social-elements.media {
  justify-content: space-evenly;
}

.social-elements p {
  font-size: var(--wl-font-size-large);
  margin: 16px 0;
  text-align: center;
}

.social-elements .separator {
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
  margin-top: 24px;
  margin-bottom: 8px;
  width: 90%;
}

.social-links img {
  border-radius: 50%;
  width: 34px;
}

.social-media {
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 0;
}

.social-media-center {
  margin: 0 auto;
}

.social-links {
  grid-column-gap: 44px;
  column-gap: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.social-apps {
  display: flex;
  flex-direction: column;
}

.app-links {
  grid-column-gap: 16px;
  column-gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.app-links a {
  background-size: cover;
  height: 40px;
  width: 135px;
}

.android {
  background-image: url(/static/media/GooglePlayBadge.9459d55d.svg);
  cursor: pointer;
}

.apple {
  background-image: url(/static/media/AppStoreBadge.15f01eef.svg);
  cursor: pointer;
}

.social-links > a > div {
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  width: 34px;
}


@media screen and (min-width: 768px) {
  .social-elements {
    flex-direction: row;
    height: 180px;
    padding: 0;
  }

  .social-elements p {
    margin-bottom: 24px;
    margin-top: 0;
  }

  .social-elements .separator {
    display: none;
  }
}

@media screen and (min-width: 970px) {
  .social-wrapper {
    margin-top: 0;
  }
}

.fb-yachts h1.fb-label, .fb-yachts h2.fb-label {
  padding: 0;
  margin: 0 0 24px 0;
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
}
.fb-yachts .fb-cards .featured-boat {
  position: relative;
  border-radius: 6px;
  color: white;
  overflow: hidden;
  height: 250px;
}
.fb-yachts .fb-cards .featured-boat > a {
  margin: 0;
  padding: 0;
  color: white;
  text-decoration: none;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient {
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, 0.5) 75%);
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-logo {
  margin: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 1px;
  width: 80px;
  height: 40px;
  border-radius: 4px;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-logo img {
  border-radius: 6px;
  max-height: 100%;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content {
  position: absolute;
  margin: 12px;
  bottom: 0;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p {
  margin: 0;
  padding: 0;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-featured {
  font-size: 10px;
  padding: 2px 8px;
  text-transform: uppercase;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  color: #0F4D77;
  border-radius: 6px;
  display: none;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-price {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}
.fb-yachts .fb-cards .featured-boat .fb-gradient .fb-content p.fb-location {
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
}

.fb-yachts .fb-card-image {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .fb-cards {
    display: block;
  }
  .fb-cards .featured-boat {
    margin-bottom: 12px;
  }
}
@media screen and (min-width: 768px) {
  .fb-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .fb-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
}

.buying-experience {
  margin: 0 auto;
  max-width: var(--wl-max-content-width);
}

.buying-experience h3 {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-medium);
  text-align: center;
  margin-top: 0;
  padding: 0 16px;
}

.experience-feature .icon {
  background-size: contain;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin-top: 4px;
}

.experience-feature .icon.buying {
  background-image: url(/static/media/Buying.47e7f3dc.svg);
}

.experience-feature .icon.directory {
  background-image: url(/static/media/Directory.8dbd44f1.svg);
}

.experience-feature .icon.research {
  background-image: url(/static/media/Research.e595cacc.svg);
}

.experience-feature .icon.search-alerts {
  background-image: url(/static/media/SearchAlerts.45d81641.svg);
}

.experience-feature .icon.selling {
  background-image: url(/static/media/Selling.432a2319.svg);
}

.experience-feature .icon.services {
  background-image: url(/static/media/Services.180fa5ad.svg);
}

.buying-experience .features {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
  margin: 0 0 40px 0;
}

.experience-feature {
  display: flex;
  flex: 100% 1;
  color: #3B96D2;
}

.experience-feature:hover,
.experience-feature:visited,
.experience-feature:active {
  color: #3B96D2;
}

.experience-feature .excerpt {
  color: var(--wl-text-color-default);
  flex: 1 1;
  margin-left: 16px;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  font-weight: 400;
}

.experience-feature .excerpt h4 {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-medium);
  font-weight: 700;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.experience-feature .excerpt div.learn-more {
  color: #3B96D2;;
}

.learn-more-arrow {
  background-color: #3B96D2;
  display: inline-block;
  height: 12px;
  margin-left: 4px;
  mask: url(/static/media/RightArrow.f18c615c.svg) no-repeat center;
  -webkit-mask: url(/static/media/RightArrow.f18c615c.svg) no-repeat center;
  width: 12px;
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-small);
  font-weight: 400;
}

@media screen and (min-width: 769px) {
  .buying-experience .features {
    grid-gap: 40px;
    gap: 40px;
  }

  .experience-feature {
    display: flex;
    flex: 45% 0;
  }
}


.spinner-preloader.hidden {
  display: none;
}

.spinner-preloader {
  width: 100%;
  position: absolute;
  height: 100%;
  background: var(--wl-preload-background-image);
}

.thumbs-wrapper {
  position: relative;
}

.carousel-thumbs {
  overflow: hidden;
}

.carousel-thumbs__wrapper {
  white-space: nowrap;
  position: relative;
  transition: transform 0.3s;
}

.carousel-thumbs__img-wrapper {
  width: 99%;
  min-height: 270px;
  display: inline-block;
  overflow: hidden;
  margin-top: 1%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.carousel-thumbs__img-wrapper.selected {
  position: relative;
}

.carousel-thumbs__img-wrapper.selected:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  /* box-shadow: inset 0 0 0 4px var(--wl-border-color-five); */
}

.carousel-thumbs__img {
  width: 90%;
  height: 100%;
  object-fit: contain;
}


.thumbs-wrapper .control-next.control-arrow:hover {
  background-color: lightgray;
  transition: 0.2s;
}


.thumbs-wrapper .control-prev.control-arrow:hover {
  background-color: lightgray;
  transition: 0.2s;
}


.thumbs-wrapper .control-next.control-arrow {
  /* border-right: 1px solid var(--wl-border-color-default); */
  margin-right: 0.36em;
  position: absolute;
  display: inline-block;
  width: 3em;
  height: 6em;
  background-image: url(/static/media/RightArrow.f18c615c.svg);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: 30% 30%;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}

.thumbs-wrapper .control-next.control-arrow.hide {
  display: none;
}

.thumbs-wrapper .control-prev.control-arrow.hide {
  display: none;
}

.thumbs-wrapper .control-prev.control-arrow {
  /* border-left: 1px solid var(--wl-border-color-default); */
  margin-left: 0.36em;
  position: absolute;
  display: inline-block;
  width: 3em;
  height: 6em;
  background-image: url(/static/media/LeftArrow.800569ae.svg);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-size: 30% 30%;
  top: 40%;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .carousel-thumbs__img-wrapper {
    height: 300px;
  }

  .carousel-thumbs__img-wrapper {
    width: 24%;
  }
}

.carousel-thumbs__img-wrapper>.listing-result.listing-result-card {
  max-width: 100% !important;
  margin-left: 0px !important
}

.carousel-thumbs__img-wrapper>.listing-result.listing-result-card .description {
  padding: 0 0 2% 2% !important;
}


.social-wrapper {
  background-color: var(--wl-background-color-nineteen);
  display: block;
  padding: 0 16px;
  box-sizing: border-box;
}

.social-elements {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  justify-content: space-between;
  color: white;
  padding: 8px 16px 24px;
}

.social-elements.media {
  justify-content: space-evenly;
}

.social-elements p {
  font-size: var(--wl-font-size-large);
  margin: 16px 0;
  text-align: center;
}

.social-elements .separator {
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
  margin-top: 24px;
  margin-bottom: 8px;
  width: 90%;
}

.social-links img {
  border-radius: 50%;
  width: 34px;
}

.social-media {
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 0;
}

.social-media-center {
  margin: 0 auto;
}

.social-links {
  column-gap: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.social-apps {
  display: flex;
  flex-direction: column;
}

.app-links {
  column-gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.app-links a {
  background-size: cover;
  height: 40px;
  width: 135px;
}

.android {
  background-image: url(../../icons/GooglePlayBadge.svg);
  cursor: pointer;
}

.apple {
  background-image: url(../../icons/AppStoreBadge.svg);
  cursor: pointer;
}

.social-links > a > div {
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  width: 34px;
}


@media screen and (min-width: 768px) {
  .social-elements {
    flex-direction: row;
    height: 180px;
    padding: 0;
  }

  .social-elements p {
    margin-bottom: 24px;
    margin-top: 0;
  }

  .social-elements .separator {
    display: none;
  }
}

@media screen and (min-width: 970px) {
  .social-wrapper {
    margin-top: 0;
  }
}

.homepage-quicklinks-container {
  color: var(--wl-text-color-ten);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: var(--wl-max-content-width);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.homepage-quicklinks-container .collapse-content-details {
  width: 100%;
}

.homepage-quicklinks-container .collapse-content-details .header a {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.homepage-quicklinks-container .quicklink-list {
  display: flex;
  flex-basis: 25%;
  justify-content: flex-start;
  width: 100%;
}

.homepage-quicklinks-container .quicklink-list h2 {
  color: var(--wl-text-color-one);
  font-weight: bold;
  font-size: var(--wl-font-size-large);
  margin-top: 32px;
  padding: 0;
}

.homepage-quicklinks-container div {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.homepage-quicklinks-container .quicklink-list ul {
  margin: 0px;
  padding-inline-start: 0;
}

.homepage-quicklinks-container .quicklink-list li {
  list-style: none;
}

.homepage-quicklinks-container .quicklink-list li a {
  color: var(--wl-text-color-ten);
  line-height: var(--wl-line-height-x-large);
}

.homepage-quicklinks-container .quicklink-list li a:hover,
.homepage-quicklinks-container .quicklink-list li a:focus {
  color: var(--wl-text-color-eleven);
  text-decoration: underline;
}

.homepage-quicklinks-container .collapse-content-quicklist {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .homepage-quicklinks-container {
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  .homepage-quicklinks-container .quicklink-list {
    display: flex;
    width: 50%;
  }
}

@media screen and (min-width: 970px) {
  .homepage-quicklinks-container {
    flex-wrap: nowrap;
  }

  .homepage-quicklinks-container .quicklink-list {
    display: flex;
    justify-content: flex-start;
    width: auto;
  }
}

/** Quick links redesign */
.home-quicklinks-container {
  color: var(--wl-text-color-thirteen);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: var(--wl-font-size-medium);
  justify-content: space-evenly;
  line-height: var(--wl-line-height-medium);
  margin: 0 auto;
  max-width: var(--wl-max-content-width);
  padding: 0 0 32px;
}

.home-quicklinks-container h2 {
  margin-left: 16px;
  margin-top: 32px;
}

.home-quicklinks-container .collapse-content {
  border-bottom: 1px solid var(--wl-border-color-default);
  margin: 0;
}

.home-quicklinks-container .collapse-content .header {
  margin: 0;
}

.home-quicklinks-container .collapse-content.open .header {
  background: var(--wl-border-color-eleven);
  opacity: 0.5;
}

.home-quicklinks-container .collapse-content .header {
  font-size: 18px;
}

.home-quicklinks-container .collapse-content .header a {
  color: var(--wl-text-color-thirteen);
  font-weight: normal;
}

.home-quicklinks-container .quicklink-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-quicklinks-container .quicklink-list li {
  display: inline-block;
  flex-basis: 100%;
  padding: 8px;
}

.home-quicklinks-container .quicklink-list a {
  color: var(--wl-text-color-thirteen);
}

@media screen and (min-width: 768px) {
  .home-quicklinks-container .quicklink-list li {
    flex-basis: 47%;
  }

  .home-quicklinks-container h2 {
    margin-left: 0;
  }
}

@media screen and (min-width: 970px) {
  .home-quicklinks-container .quicklink-list li {
    flex-basis: 31%;
  }
}

.boat-list.boat-list-home {
  background-color: var(--wl-content-background-color-default);
  display: flex;
  flex-direction: column;
  margin: 0 0 -8px;
}

@media screen and (min-width: 375px) {
  .boat-list.boat-list-home {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -24px;
  }
}

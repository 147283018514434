.forum-banner {
  background-color: var(--wl-background-color-eighteen);
  display: flex;
  flex-direction: column;

  background-image: 
    url('../../icons/ForumBanner.svg'),
    url('../../icons/Person.svg');
  
  background-position: 
    top -20px left 10px,
    bottom right 10px;
  
  background-repeat: no-repeat;
}

.forum-banner .forum-banner-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 20px
}

.forum-banner-elements .forum-title {
  text-align: center;
  color: white;
  margin-right: 30px;
  font-size: 18px;
}

.forum-banner-elements .forum-button-container {
  background-color: var(--wl-background-color-seventeen);
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 13px 8px;
}

.forum-button-container .forum-button {
  text-decoration: none;
}

.forum-button-container .forum-button-text:before {
  content: url('../../icons/Forum.svg');
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.forum-button-container .forum-button-text {
  color: var(--wl-text-color-sixteen);
}


@media screen and (max-width: 600px) {
  .forum-banner-elements .forum-title {
    text-align: left;
  }

  .forum-banner .forum-banner-elements {
    display:inline-block;
    flex-direction: row;
    height: 20%;
  }
  .forum-banner-elements .forum-button-container {
    margin-top: 20px;
  }
}
.default-image-wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
}

.default-image-centered-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-image-text {
  text-transform: uppercase;
  font-size: var(--wl-font-size-medium);
  color: var(--wl-text-color-five);
  white-space: nowrap;
}

.default-image-icon {
  background-image: url(./photo_camera.svg);
  background-size: contain;
  margin-bottom: 4px;
}

a .default-image-wrapper {
  position: inherit;
}

@media screen and (min-width: 768px) {
  .default-image-text {
    font-size: var(--wl-font-size-x-large);
  }
}

.qs-block .qs-ad-flex a {
  line-height: 0;
}

#quick-search-content {
  display: block;
}

.qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container {
  display: inline-block;
  border-bottom: 1px solid #F2F2F2;
  flex-grow: 1;
}

.qs-block .qs-filters .quick-search-container .quick-search .top-row {
  display: flex;
  justify-content: space-between;
}

.qs-filters .quick-search-container .quick-search .top-row label {
  padding: 0 10px;
  cursor: pointer;
  color: #999999;
  font-weight: 500;
}

.qs-filters .quick-search-container .quick-search .top-row .qs-checked {
  color: var(--wl-text-color-twenty);
  border-bottom: 2px solid var(--wl-background-color-nineteen);
}

.qs-filters .quick-search-container .quick-search .top-row .qs-category-advanced-search {
  color: var(--wl-text-color-twenty);
  border-bottom: 1px solid #F2F2F2;
  min-width: 120px;
  white-space: nowrap;
  cursor: pointer;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row {
  color: var(--wl-text-color-twenty);
  font-weight: 600;
}

.qs-filters .quick-search-container .quick-search .top-row input[type=radio] {
  opacity: 0;
  width: 0;
  padding: 0;
  margin: 0;
  display: none;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search:disabled {
  background: var(--wl-button-disabled-background);
  color: var(--wl-text-color-disabled);
  cursor: not-allowed;
}

.qs-block .qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container > label {
  white-space: nowrap;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location {
  display: block;
  margin-top: 8px;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .year,
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .price,
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--wl-text-color-twelve);
  padding: 11px;
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--wl-input-box-border-color);
  border-top: 1px solid var(--wl-input-box-border-color);
  border-bottom: 1px solid var(--wl-input-box-border-color);
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid var(--wl-input-box-border-color);
  border-top: 1px solid var(--wl-input-box-border-color);
  border-bottom: 1px solid var(--wl-input-box-border-color);
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms div.active {
  color: var(--wl-text-color-four);
  background-color: var(--wl-background-color-nineteen);
}

.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show .icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url('./chevron-more.svg') no-repeat;
  vertical-align: middle;
  margin-left: 4px;
}
.qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show .icon.collapse {
  background: url('./chevron-less.svg') no-repeat;
}

#class-dropdown:disabled {
  background-color: rgba(233, 233, 233, 0.30);
}

.qs-content-body {
  font-size: var(--wl-font-size-medium);
}

.qs-content-body .ad {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 375px) {
  .qs-content-element {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

.qs-content-element.qs-block {
  max-width: var(--wl-max-content-width);
  margin: 0 auto;
  padding: 20px 10px;
}

@media screen and (max-width: 767px) {
  .qs-content-body:nth-child(4) > div:not(:nth-child(2)) {
    max-width: 100%;
    width: 100%;
  }

  .qs-block {
    display: flex;
    background: #F2F2F2;
  }

  .qs-block .qs-filters {
    width: 100%;
  }
  .qs-block .qs-filters .quick-search-container {
    display: block;
  }
  .qs-block .qs-filters .quick-search-container .quick-search .top-row .quick-search-category-selectors-container {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    width: 100%;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location div.dropdown-container, .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location div.input-field-container {
    display: block;
    margin-bottom: 8px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.min,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.max {
    margin-right: 4px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length .qs-uoms {
    margin-bottom: 8px;
  }
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search {
    display: block;
    margin-top: 16px;
  }
  .qs-filters .quick-search-container .quick-search .bottom-row .qs-category-advanced-search,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .qs-category-show {
    border-bottom: 0px;
    min-width: 100%;
    display: block;
    text-align: center;
    margin-top: 12px;
    font-weight: 400;
  }
  .qs-content-element.qs-block {
    display: block;
  }
  .qs-ad-flex {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) {
  #quick-search-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .qs-block {
    display: flex;
  }

  .qs-block .qs-filters {
    flex: 1;
    height: var(--wl-quicksearch-t-height);
  }

  .qs-block .qs-filters .quick-search-container {
    height: 100%;
  }

  .qs-block .qs-filters .quick-search-container .quick-search {
    position: static;
    width: 100%;
    margin-right: 0;
    height: auto;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "condition class makemodel" "price year length" ". . search";
    column-gap: 16px;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location.engines {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "condition year price" ". . search";
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-makemodel {
    grid-area: makemodel;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-class {
    grid-area: class;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .input-condition {
    grid-area: condition;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .length {
    grid-area: length;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .year {
    grid-area: year;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .price {
    grid-area: price;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .button-search {
    grid-area: search;
  }

  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.min,
  .qs-block .qs-filters .quick-search-container .quick-search form.bottom-row.bottom-row__no-location .area-range.max {
    margin-right: 8px;
  }

  .qs-block .qs-ad-flex {
    display: flex;
    flex: 0 0 300px;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
  }
}

@media screen and (min-width: 960px) {
  .qs-block .qs-filters {
    height: var(--wl-quicksearch-d-height);
  }

  .qs-block .qs-ad-flex {
    position: static;
    width: 100%;
    margin-right: 0;
    margin-top: 0;
    height: auto;
    display: flex
  }
}

/*# sourceMappingURL=qs-alt-styles.css.map */

.buying-experience {
  margin: 0 auto;
  max-width: var(--wl-max-content-width);
}

.buying-experience h3 {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-medium);
  text-align: center;
  margin-top: 0;
  padding: 0 16px;
}

.experience-feature .icon {
  background-size: contain;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  margin-top: 4px;
}

.experience-feature .icon.buying {
  background-image: url(../../icons/Buying.svg);
}

.experience-feature .icon.directory {
  background-image: url(../../icons/Directory.svg);
}

.experience-feature .icon.research {
  background-image: url(../../icons/Research.svg);
}

.experience-feature .icon.search-alerts {
  background-image: url(../../icons/SearchAlerts.svg);
}

.experience-feature .icon.selling {
  background-image: url(../../icons/Selling.svg);
}

.experience-feature .icon.services {
  background-image: url(../../icons/Services.svg);
}

.buying-experience .features {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin: 0 0 40px 0;
}

.experience-feature {
  display: flex;
  flex: 100%;
  color: #3B96D2;
}

.experience-feature:hover,
.experience-feature:visited,
.experience-feature:active {
  color: #3B96D2;
}

.experience-feature .excerpt {
  color: var(--wl-text-color-default);
  flex: 1;
  margin-left: 16px;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  font-weight: 400;
}

.experience-feature .excerpt h4 {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-medium);
  font-weight: 700;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.experience-feature .excerpt div.learn-more {
  color: #3B96D2;;
}

.learn-more-arrow {
  background-color: #3B96D2;
  display: inline-block;
  height: 12px;
  margin-left: 4px;
  mask: url(../../icons/RightArrow.svg) no-repeat center;
  -webkit-mask: url(../../icons/RightArrow.svg) no-repeat center;
  width: 12px;
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-small);
  font-weight: 400;
}

@media screen and (min-width: 769px) {
  .buying-experience .features {
    gap: 40px;
  }

  .experience-feature {
    display: flex;
    flex: 45% 0;
  }
}

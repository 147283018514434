.input-field-container {
  position: relative;
}

.input-field-label {
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-small);
}

.input-field-label-error {
  color: var(--wl-background-color-eight);
}

.input-field-label-success {
  color: var(--wl-text-color-twelve);
}

.input-field-input {
  background: transparent;
  border: none;
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-medium);
  padding: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
}

.input-field-input-success,
.input-field-input-error
{
  padding-right: 30px;
}

.input-field-input-active {
  color: var(--wl-text-color-default);
}

.input-field-input-container {
  background: var(--wl-brand-color-three);
  border: 1px solid var(--wl-input-box-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 43px;
  width: 100%;
}

.input-field-input-container:after{
  background-origin: content-box;
  background-repeat: no-repeat;
  height: 24px;
  padding: 10px;
  position: absolute;
  pointer-events: none;
  right: 0;
  width: 24px;
}

.input-field-input-container-error:after{
  background-image: url(../../icons/cancel.svg);
  content: '';

}

.input-field-input-container-success:after{
  background-image: url(../../icons/check_circle.svg);
  content: '';
}

.input-field-input-container-error {
  border-color: var(--wl-background-color-eight);
}

.input-field-input-container-success {
  border-color: var(--wl-background-color-seven);
}

.input-field-message {
  color: var(--wl-text-color-twelve);
  display: block;
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-default);
  position: absolute;
}

.input-field-message-error {
  color: var(--wl-background-color-eight);
}

/* Component */
.home-blog-section .blog-section-title {
  font-size: 29px;
  line-height: var(--wl-line-height-default);
  font-weight: 700;
  margin: 50px 0 12px 0;
}

.home-blog-section .blog-section-title.row-layout {
  margin: 0 0 10px 0;
}

.home-blog-section .blog-section-read-more {
  margin: 24px 0 40px 0;
}

.home-blog-section .blog-section-read-more.row-layout {
  margin: 0 !important;
  padding: 0 16px;
}

.home-blog-section .blog-section-read-more a {
  font-size: var(--wl-font-size-small);
  font-weight: 400;
  color: var(--wl-text-color-default)
}

.home-blog-section .blog-section-read-more.row-layout a {
  font-weight: 500;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: #3B96D2;
}

.blog-section-read-more.row-layout a:hover,
.blog-section-read-more.row-layout a:visited,
.blog-section-read-more.row-layout a:active {
  color: #3B96D2;
}

/* Articles */
.home-blog-section .article-title {
  font-weight: 700;
}

.home-blog-section .row-layout .article-title {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.home-blog-section .article-category {
  font-weight: 400;
}

.home-blog-section .row-layout .article-category {
  font-weight: 500;
  font-size: var(--wl-font-size-xx-small);
  line-height: var(--wl-line-height-xx-small);
}

.home-blog-section .article-text-container {
  box-sizing: border-box;
  font-size: var(--wl-font-size-x-small);
}

.home-blog-section .article-picture {
  object-fit: cover;
}

/* Main article */
.home-blog-section .home-main-article {
  position: relative;
}

.home-main-article .article-picture {
  position: relative;
  height: 140px;
  width: 100%;
}

.home-main-article .article-text-container {
  position: absolute;
  background-color: var(--wl-box-shadow-two);
  color: var(--wl-text-color-four);
  bottom: 4px;
  padding: 4px 6px;
  width: 100%;
}

.home-main-article .row-layout .article-text-container {
  padding: 0;
}

/* Secondary articles */
.home-blog-section .home-secondary-articles {
 display: grid;
 row-gap: 8px;
 margin: 8px 0;
}

.home-blog-section .row-layout .home-secondary-articles {
 padding: 1em 0;
}

.home-blog-section .row-layout .home-secondary-articles article{
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #EAEAEA;
  margin: 0 0.6em 0 0;
}

.home-secondary-articles article{
  cursor: pointer;
}

.home-article .article-picture{
  display: none;
}

.home-article .article-text-container {
  box-sizing: border-box;
  padding: 4px 18px;
}

.home-article .article-title, .home-article .article-title a {
  color: var(--wl-blog-section-secondary-title-color);
}

.home-article .article-category {
  color: var(--wl-text-color-default);
}

.home-blog-section .row-layout .home-secondary-articles {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.home-blog-section .row-layout article .article-picture {
  display: block;
  width: 100%;
  height: 190px;
  border-radius: 5px;
}

.home-blog-section .row-layout .home-article .article-text-container {
  padding: 4px 16px;
}

.home-blog-section .row-layout .home-article .article-category {
  text-transform: uppercase;
  color: #3B96D2;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.home-blog-section .row-layout .home-article .article-title {
  margin: 10px 0;
}

.home-blog-section .row-layout .home-article .article-title a{
  color: #334556;
}

.home-blog-section .row-layout .home-article .article-excerpt {
  color: #686868;
  margin-bottom: 10px;
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-line-height-medium);
}

.row-layout article {
  flex: 1 0 75%;
  margin-right: 1rem;
}

.no-image {
  background: url(../DefaultImage/photo_camera.svg) no-repeat center;
  height: 190px;
  width: 100%;
}


@media screen and (min-width:768px) {
  /* Component */
  .home-blog-section .blog-section-title {
    margin: 50px 0 36px 0;
  }

  .home-blog-section .blog-section-title.row-layout {
    margin: 0 10px 0 0;
  }

  .home-blog-section .blog-section-read-more {
    margin: 24px 0 14px 0;
  }

  .home-blog-section .blog-section-read-more.row-layout {
    margin: 20px 0;
  }

  /* Main article */
  .home-main-article .article-picture {
    height: 370px;
  }

  .home-main-article .article-text-container {
    padding: 8px 16px;
  }

  .home-main-article .article-title {
    font-size: var(--wl-font-size-large);
  }

  .home-main-article .article-category {
    font-size: var(--wl-font-size-medium);
  }

  /* Secondary Articles */

  .home-blog-section .home-secondary-articles {
    gap: 24px 26px;
    margin: 25px 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 74px 74px;
  }

  .home-blog-section .home-article {
    display: flex;
  }

  .home-article .article-picture {
    display: block;
    height: 74px;
    min-width: 125px;
    max-width: 125px;
  }

  .row-layout .home-article .article-picture {
    display: block;
    height: 74px;
    min-width: none;
    max-width: none;
  }

  .home-article .article-text-container {
    width: auto;
    padding: 10px 16px;
  }

  /* row layout*/

  .home-blog-section .home-articles-container.row-layout {
    display: flex;
  }

  .home-blog-section .row-layout .home-article {
    display: block;
  }

  .home-blog-section .row-layout article {
    flex: 1 0 60%;
  }

  .home-blog-section .row-layout article .article-picture, .row-layout article .article-text-container {
    display: block;
  }
}

@media screen and (min-width:970px) {
  /* Component */
  .home-blog-section .blog-section-title.row-layout {
    margin: 0 0 10px 0;
  }

  .home-blog-section .blog-section-read-more {
    margin: 27px 0 11px 0;
  }

  .home-blog-section .blog-section-read-more.row-layout {
    margin: 10px 0;
  }

  .home-blog-section .home-articles-container {
    position: relative;
    display: grid;
    column-gap: 24px;
    grid-template-columns: 1fr 355px;
  }

  .home-blog-section .row-layout .home-secondary-articles {
    grid-gap: 0;
    gap: 0;
  }

  .home-blog-section .home-secondary-articles {
    grid-row-gap: 24px;
    grid-template-rows: 74px 74px 74px 74px;
    grid-template-columns: 1fr;
    margin: 0;
  }

  .home-blog-section .row-layout article {
    flex: 1 0 20%;
  }
}

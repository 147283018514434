.hero {
  margin: 0 auto;
  max-height: 345px;
  max-width: var(--wl-max-header-width);
}

/*PFBA Image Resize*/
#div-gpt-ad-PFBA iframe{
  min-width: 100%!important;
  width: 1px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

#div-gpt-ad-PFBA div[id^="google_ads_iframe_"]{
  width: 100% !important;
  height: 100% !important;
}

.hero-text-title {
  font-weight: 700;
}

.hero .hero-image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.hero-elements-logo {
  object-fit : contain;
  max-width: 100px;
  padding: 10px;
}

.hero-elements .hero-elements-button {
  color: inherit;
  text-decoration: none;
}

/* Setting min-width to 0 in order to prevent children text fields from overflowing */
.hero-elements {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-right: 4px;
  min-width: 0;
  padding: 10px;
}

a.hero-elements-button::after {
  background-image: url(../../icons/Link.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  content: "";
  display: inline-block;
  height: 18px;
  margin-left: 4px;
  vertical-align: middle;
  width: 18px;
}

@media screen and (min-width: 500px) {
  .hero {
    max-height: 100%;
  }

  .hero .hero-image {
    height: 300px;
  }

  .hero-elements {
    margin-right: 16px;
  }
  
}

@media screen and (min-width:768px) {
  .hero {
    max-height: none;
  }

  .hero .hero-image {
    height: 350px;
  }

  .hero-elements {
    line-height: var(--wl-line-height-large);
  }
  
}

@media screen and (min-width:970px) {

  .hero {
    display: block;
    height: 500px;
    position: relative;
  }

  .hero .hero-image {
    height: 500px;
  }
}

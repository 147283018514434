.button-search {
  grid-column: span 2;
  background: var(--wl-background-color-one);
  border: 1px solid var(--wl-background-color-one);
  border-radius: 4px;
  color: var(--wl-text-color-four);
  font-weight: bold;
  font-size: var(--wl-font-size-medium);
  height: 43px;
  line-height: var(--wl-line-height-default);
  padding: 12px 12px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.bottom-row {
  align-items: flex-end;
  display: grid;
  grid-gap: 12px 8px;
  grid-template-columns: repeat(2, fit-content(100%));
  justify-content: center;
}

.quick-search {
  background-color: var(--wl-brand-color-three);
  padding: 30px 16px;
  width: auto;
  box-sizing: border-box;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.08),
    0 2px 4px rgba(0, 0, 0, 0.08),
    0 1px 2px rgba(0, 0, 0, 0.08);
}

.quick-search-container h1 {
  font-style: normal;
  font-weight: 900;
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-default);
  color: var(--wl-text-color-default);
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.83);
  margin-top: -2px;
}

.quick-search .option-item-button:not(.title) {
  cursor: pointer;
}

.quick-search .option-item-button:not(.title)[disabled],
.quick-search .option-item-button:not(.title)[disabled]:focus,
.quick-search .option-item-button:not(.title)[disabled]:hover {
  background-color: inherit;
  color: inherit;
  cursor: default;
}

.quick-search .option-item-button:not(.title):focus,
.quick-search .option-item-button:not(.title):hover,
.quick-search .option-item-button:not(.title).selected {
  color: var(--wl-text-color-four);
  background-color: var(--wl-brand-color-one);
}

.quick-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.top-row .quick-search-category-selectors-label {
  color: var(--wl-text-color-twelve);
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-small);
}

.top-row .quick-search-category-selectors-container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(2, 50%);
  padding-bottom: 6px;
}

.input-length {
  grid-column: span 2;
}

/* Hide arrows on numeric inputs */
.quick-search input[type='number']::-webkit-inner-spin-button,
.quick-search input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quick-search input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (min-width: 768px) {
  .button-search,
  .input-length {
    grid-column: auto;
  }

  .bottom-row {
    justify-content: left;
    grid-template-columns: repeat(4, 140px) 112px;
  }

  .bottom-row__no-location {
    grid-template-columns: repeat(2, 1fr) repeat(2, 129px);
  }

  .quick-search {
    padding: 18px 45px;
    height: 180px;
  }

  .top-row .quick-search-category-selectors-label,
  .bottom-row label {
    font-size: var(--wl-font-size-small);
    font-weight: 700;
    color: var(--wl-text-color-six);
  }

  .top-row .quick-search-category-selectors-container {
    grid-template-columns: repeat(4, fit-content(100%));
  }
}

@media screen and (min-width: 970px) {
  .quick-search {
    width: 768px;
    background-color: var(--wl-brand-color-three);
    box-shadow:
      0px 4px 8px rgba(0, 0, 0, 0.08),
      0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: absolute;
    top: -39px;
  }

  .quick-search-container {
    position: relative;
    box-shadow: none;
    height: 139px;
  }

  .bottom-row {
    grid-template-columns: repeat(5, 129px);
  }

  .bottom-row__no-location {
    grid-template-columns: repeat(4, 120px) repeat(2, 100px) 60px;
  }
}

#ad-pfba .adslot-target {
  display: block;
}

.ad-two-hero {
  height: 100%;
}

.pfba {
  max-width: var(--wl-max-header-width);
  position: relative;
  max-height: 500px;
  margin: 0 auto;
  aspect-ratio: 1440 / 500;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .pfba {
    padding-bottom: 94px;
  }
}

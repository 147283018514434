.listing-result.listing-result-card {
  background-color: var(--wl-content-background-color-default);
  font-family: var(--wl-font-family-default);
  color: var(--wl-text-color-one);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 24px;
  max-width: 100%;
  flex: 1 0 100%;
  border: 0;
}

.listing-result.listing-result-card .inner {
  flex-direction: column;
}

.listing-result.listing-result-card .image-container {
  width: 100%;
  max-width: initial;
}

.listing-result.listing-result-card .image-results {
  border-radius: 4px;
}

.listing-result.listing-result-card .description {
  padding: 0;
  margin-top: 6px;
  width: auto;
}

.listing-result.listing-result-card .name {
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-default);
  font-weight: bold;
  max-width: initial;
  margin: 2px 0;
}

.listing-result.listing-result-card .price {
  line-height: var(--wl-line-height-default);
  font-size: var(--wl-font-size-medium);
  color: var(--wl-price-text-color);
  font-weight: bold;
  text-align: left;
  float: initial;
  margin: 2px 0;
  clear: both;
}

.listing-result.listing-result-card .location {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
  text-transform: uppercase;
  color: var(--wl-text-color-twelve);
  margin: 2px 0 0;
}

@media screen and (min-width: 375px) {
  .listing-result.listing-result-card {
    max-width: calc(50% - 24px);
    margin-left: 24px;
  }

  .listing-result.listing-result-card .name {
    font-size: var(--wl-font-size-small);
    line-height: var(--wl-line-height-default);
  }
}

@media screen and (min-width: 768px) {
  .listing-result.listing-result-card {
    max-width: calc(33% - 24px);
  }
}

@media screen and (min-width: 970px) {
  .listing-result.listing-result-card {
    max-width: calc(25% - 24px);
  }

  .listing-result.listing-result-card .name {
    font-size: var(--wl-font-size-medium);
    line-height: var(--wl-line-height-default);
  }
}

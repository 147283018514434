.listing-result {
  width: 100%;
  background: var(--wl-content-background-color-default);
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-right: 10px;
  position: relative;
  list-style: none;
  border: 1px solid var(--wl-border-color-default);
}

.listing-result .inner {
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
}

.search-results .boat-list .listing-result {
  border: none;
}
.search-results .boat-list .listing-result .inner {
  border-radius: 8px;
  border: 1px solid var(--wl-border-color-default);
}

.listing-result .image-container {
  position: relative;
  overflow: hidden;
  width: 45%;
  max-width: 253px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.search-results .boat-list.boat-list-results .listing-result .image-container {
  overflow: visible;
}

.listing-result .image-container .dummy {
  padding-top: 66.666666666666%; /* 4:3 aspect ratio */
}

.listing-result .image-container .image {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.listing-result .image-container .image .image-results {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.listing-result .image-container .image .preloader {
  width: 100%;
  position: absolute;
  margin: auto;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
}

.listing-result .image-container .preloader {
  z-index: 3;
  background: var(--wl-preload-background-image);
  height: 100%;
  width: 100%;
}

.listing-result .image-container .preloader.hidden {
  opacity: 0;
  transition: opacity 0.5s ease 0.5s;
}

.listing-result .description {
  color: var(--wl-text-color-five);
  text-align: center;
  position: relative;
  width: 55%;
  line-height: 1;
  flex-grow: 1;
  font-family: var(--wl-font-family-default);
  font-style: normal;
  font-weight: normal;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.listing-result .top {
  float: left;
  width: 100%;
}

.listing-result .bottom {
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.listing-result .name {
  float: left;
  clear: both;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-seven);
  overflow: hidden;
  text-align: left;
  margin-bottom: 3px;
  max-height: 80px;
  max-width: 100%;
}

.listing-result-enhanced .name {
  max-width: 50%;
}

.listing-result .name h2 {
  margin: 0;
  font-weight: normal;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
}

.listing-result a .name {
  color: var(--wl-text-color-seven);
}

.listing-result .price {
  float: left;
  clear: both;
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  margin-bottom: 3px;
  color: var(--wl-price-text-color);
  text-align: left;
}

.listing-result .location {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-xxx-small);
  color: var(--wl-text-color-two);
  float: left;
  clear: both;
  margin-bottom: 3px;
}

.listing-result a .location {
  color: var(--wl-text-color-five);
}

.listing-result .offered-by {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
  color: var(--wl-text-color-seven);
  float: left;
  clear: both;
  text-align: left;
  align-items: flex-end;
  flex-grow: 1;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-result a .offered-by {
  color: var(--wl-text-color-seven);
}

.listing-result-enhanced .contact {
  position: relative;
}

.listing-result-enhanced .contact-seller {
  background: var(--wl-background-color-one);
  bottom: 9.44px;
  border-radius: 4px;
  border: 0 solid;
  cursor: pointer;
  color: var(--wl-text-color-four);
  float: right;
  font-size: var(--wl-font-size-x-small);
  font-weight: 700;
  line-height: var(--wl-line-height-default);
  padding-bottom: 8px;
  padding-top: 8px;
  right: 10px;
  width: 102px;
  z-index: 4;
}

.listing-result-enhanced .dealer {
  display: grid;
  width: 80%;
  min-height: 90px;
  align-items: end;
  grid-template-rows: 1fr auto;
}

.listing-result-enhanced .dealer-logo {
  max-height: 40px;
  max-width: 100px;
}

.listing-result-enhanced .description {
  width: auto;
}

.listing-result-enhanced .image-container {
  max-width: 100%;
  width: auto;
}

.listing-result-enhanced .image-container .dummy {
  padding-top: 46%;
}

.listing-result-enhanced .inner {
  flex-direction: column;
}

.listing-result-enhanced .name {
  font-size: var(--wl-font-size-large);
  font-weight: bold;
  line-height: var(--wl-line-height-default);
}

.listing-result-enhanced .offered-by {
  font-size: var(--wl-font-size-xxx-small);
  line-height: var(--wl-line-height-medium);
}

.listing-result-enhanced .price{
  font-size: var(--wl-font-size-x-large);
  font-weight: bold;
  line-height: var(--wl-line-height-default);
}

.listing-result-enhanced .sold-by {
  color: var(--wl-text-color-two);
}

@media screen and (min-width: 768px) {
  .listing-result .name {
    clear: none;
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
    max-width: 48%;
  }

  .listing-result .name h2 {
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
  }

  .listing-result .price {
    clear: none;
    font-size: var(--wl-font-size-large);
    line-height: var(--wl-line-height-large);
    float: right;
  }

  .listing-result .location {
    font-size: var(--wl-font-size-xx-small);
    line-height: var(--wl-line-height-xx-small);
  }

  .listing-result .offered-by {
    font-size: var(--wl-font-size-xx-small);
    line-height: var(--wl-line-height-small);
  }

  .listing-result-enhanced .dealer-logo {
    max-height: 60px;
    max-width: 155px;
  }

  .listing-result-enhanced .image-container {
    width: 45%;
  }

  .listing-result-enhanced .image-container .dummy {
    padding-top: 66.666666666666%; /* 4:3 aspect ratio */
  }

  .listing-result-enhanced .inner {
    flex-direction: row;
  }

  .listing-result-enhanced .name {
    font-size: var(--wl-font-size-x-large);
  }

  .listing-result-enhanced .offered-by {
    font-size: var(--wl-font-size-x-small);
    line-height: var(--wl-line-height-small);
  }
}

.icons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 18px 8px 8px 8px;
  box-sizing: border-box;
  background: linear-gradient(0deg,rgba(0,0,0,.7),transparent);
  z-index: 10;
}
.icons > div {
  transform: scale(1.35);
}
.icons > div + div {
  margin-left: 10px;
}
.icons > div:hover .icon-tooltip {
  display: block;
}

.icons .icon-tooltip {
  font-size: var(--wl-font-size-x-small);
  color: #ececec;
  transform: scale(.65);
  transform-origin: left bottom;
  display: block;
  width: fit-content;
  white-space: nowrap;
  background: #2d3e4f;
  padding: 8px 14px 9px;
  position: absolute;
  bottom: 20px;
  display: none;
}
.icon-tooltip:before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #2d3e4f;
  position: absolute;
  bottom: -11px;
  left: 3px;
}

.listing-card-image-cpyb {
  background: url(../../icons/cpyb.svg) no-repeat;
  height: 10px;
  width: 19px;
}
.listing-card-image-cpyb .icon-tooltip {
  left: -2px;
  width: 320px;
  white-space: pre-wrap;
}
.listing-card-image-video {
  background: url(../../icons/video-outline.svg) no-repeat;
  height: 12px;
  width: 15px;
  background-position: 0 -2px;
  background-size: 15px 15px;
}
.listing-card-image-video .icon-tooltip {
  left: -3px;
}
.listing-card-image-360 {
  background: url(../../icons/360-listing.svg) no-repeat;
  height: 10px;
  width: 14px;
}
.listing-card-image-360 .icon-tooltip {
  left: -3px;
}
.listing-card-image-delivery {
  background: url(../../icons/localDelivery-listing.svg) no-repeat;
  height: 10px;
  width: 19px;
}
.listing-card-image-delivery .icon-tooltip {
  left: 0px;
}

.banner-attribute {
  position: absolute;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  line-height: 20px;
}
.banner-attribute.IN_STOCK {
  background: #00AE48;
}
.banner-attribute.AVAILABLE_SOON {
  background: #E37900;
}
.banner-attribute.SALE_PENDING {
  background: #E4B700;
}
.banner-attribute.NEW_ARRIVAL {
  background: #008BE5;
}

.listing-result:not(.listingStyleDefault) .banner-attribute,
.listing-result:not(.listingStyleDefault) .icons {
  display: none;
}

.listing-result.listingStyleDefault .name h2 {
  font-size: var(--wl-font-size-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  max-width: 270px;
  overflow: hidden;
}
.listing-result.listingStyleDefault .location {
  line-height: var(--wl-line-height-medium);
  text-align: left;
}
.listing-result.listingStyleDefault:not(.isHome) .location {
  position: absolute;
  left: 10px;
  top: 32px;
}
.listing-result.listingStyleDefault .location > div {
  margin-top: 3px;
}
.listing-result-enhanced.listingStyleDefault .location > div {
  margin-top: 2px;
}
.listing-result.listingStyleDefault.isHome .location > div,
.listing-result-enhanced.listingStyleDefault.isHome .location > div {
  display: none;
}
.listing-result.listingStyleDefault .price {
  font-size: var(--wl-font-size-medium);
  text-align: right;
}
.listing-result.listingStyleDefault .price > div {
  font-size: var(--wl-font-size-xx-small);
}

.listing-result.listingStyleDefault .contact-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.listing-result.listingStyleDefault .contact + .contact {
  margin-top: 8px;
}
.listing-result.listingStyleDefault .contact-seller,
.listing-result.listingStyleDefault .live-video-tour {
  font-size: var(--wl-font-size-medium);
  background: #2980B9;
  border-radius: 50px;
  width: 150px;
}
.listing-result.listingStyleDefault .live-video-tour {
  bottom: 9.44px;
  cursor: pointer;
  color: var(--wl-text-color-four);
  float: right;
  font-weight: 700;
  line-height: var(--wl-line-height-default);
  padding-bottom: 8px;
  padding-top: 8px;
  right: 10px;
  z-index: 4;
  background: #ffffff;
  color: #2980B9;
  border: 1px solid #2980B9;
  box-sizing: border-box;
}
.listing-result.listingStyleDefault .contact-seller div {
  background: url(../../icons/envelope-mail.svg) no-repeat;
  width: 18px;
  height: 11px;
}
.listing-result.listingStyleDefault .live-video-tour div {
  background: url(../../icons/video-icon.svg) no-repeat;
  width: 16px;
  height: 11px;
}

.listing-result.listingStyleDefault .dealer-logo {
  width: 75px;
  margin-bottom: 10px;
}
.listing-result.listing-result-enhanced.listingStyleDefault .description {
  background: #E7F4F9;
}

.listing-result.listingStyleDefault .manufacturer-tag {
  border-radius: 12px 0 0 12px;
  background: linear-gradient(45deg,#F1860D,#C15925);
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  position: absolute;
  top: 15px;
  right: 0;
  width: 200px;
}
.listing-result.listing-result-enhanced.listingStyleDefault .manufacturer-tag {
  display: none;
}

@media screen and (min-width: 768px) {
  .listing-result.listing-result-enhanced.listingStyleDefault:not(.isHome) .image-container {
    width: 300px;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault:not(.isHome) .image-container {
    width: 240px;
  }
}

@media screen and (max-width: 767px) {
  .listing-result.listing-result-enhanced.listingStyleDefault .price {
    float: none;
    clear: none;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .location {
    position: relative;
    top: 0;
    left: 0;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .price {
    text-align: left;
  }

  .listing-result.listingStyleDefault .contact-area {
    flex-direction: row;
  }
  .listing-result.listingStyleDefault .contact-seller,
  .listing-result.listingStyleDefault .live-video-tour {
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .listing-result:not(.listing-result-enhanced).listingStyleDefault .live-video-tour {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }
  .listing-result.listingStyleDefault .contact + .contact {
    margin-top: 0;
    margin-left: 8px;
  }

  .listing-result.listing-result-enhanced.listingStyleDefault .description {
    height: 133px;
  }

  .listing-result.listingStyleDefault .manufacturer-tag {
    bottom: 0;
    top: initial;
    border-radius: 0;
    width: 100%;
    font-size: var(--wl-font-size-xxx-small);
    line-height: 14px;
  }
  .listing-result.listingStyleDefault h2.manufacturer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
  }
  .listing-result.listingStyleDefault .dealer.listingStyleDefault-manufacturer .dealer {
    height: 50px;
  }
  .listing-result.listingStyleDefault .dealer.listingStyleDefault-manufacturer .dealer-logo {
    width: 50px;
    margin-bottom: 8px;
    margin-top: 5px;
    vertical-align: bottom;
  }

  .banner-attribute {
    font-size: 10px;
    line-height: 14px;
  }

  .listing-result-enhanced .dealer {
    min-height: 65px;
  }
}
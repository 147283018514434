.main-fsbo-ad {
  width: calc(100% + 20px);
  background-color: var(--wl-background-color-default);
  margin-top: 20px;
  margin-left: -10px;
  justify-content: space-between;
  padding: 24px 0;
  margin-bottom: 40px;
}

.main-fsbo-ad .left {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-fsbo-ad .left .title {
  font-size: var(--wl-font-size-xx-large);
  line-height: var(--wl-line-height-xx-large);
  margin-bottom: 17px;
  font-weight: bold;
}

.main-fsbo-ad .left .fsbo-text {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
  color: var(--wl-text-color-six);
}

.main-fsbo-ad .left a {
  font-size: var(--wl-font-size-large);
  line-height: var(--wl-line-height-large);
  font-weight: bold;
  color: var(--wl-text-color-four);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: var(--wl-background-color-one);
  border-radius: 4px;
  cursor: pointer;
}

.main-fsbo-ad .left .fsbo-link {
  display: flex;
  margin-top: 17px;
}

.main-fsbo-ad .right {
  display: flex;
  height: 110px;
  justify-content: flex-end;
  margin: auto;
  overflow: visible;
  padding: 0 16px;
}

.main-fsbo-ad .right .fsbo-image {
  background-position: 53% 69%;
  background-size: 130%;
  border-radius: 4px;
  height: 130%;
  margin-top: 23px;
  width: 100%;
}

@media screen and (min-width: 375px) {
  .main-fsbo-ad {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
  .main-fsbo-ad .right {
    height: 120px;
  }
  .main-fsbo-ad .right .fsbo-image {
    background-position: 53% 66%;
  }
}

@media screen and (min-width: 768px) {
  .main-fsbo-ad {
    display: flex;
  }

  .main-fsbo-ad .right {
    height: 100px;
    width: 100%;
    align-items: center;
  }

  .main-fsbo-ad .left {
    padding-right: 0;
    min-width: 368px;
  }

  .main-fsbo-ad .right .fsbo-image {
    height: 279px;
    margin-top: 0;
  }
}

@media screen and (min-width: 970px) {
  .main-fsbo-ad {
    margin-left: 0;
    width: 100%;
  }

  .main-fsbo-ad .left {
    padding-left: 40px;
  }

  .main-fsbo-ad .right .fsbo-image {
    width: 100%;
    max-width: 735px;
    height: 273px;
    background-size: 735px;
    background-position: 25% 75%;
    margin-right: 4%;
  }
}
